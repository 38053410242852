import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEYS } from '@utils/constants/local-storeage-keys.constants';

export const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, accessToken: null, refreshToken: null },
    reducers: {
        setCredentials: (state, action) => {
            const { user, accessToken, refreshToken } = action.payload;
            state.user = user;
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;

            localStorage.setItem(
                LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY,
                accessToken
            );
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.REFRESH_TOKEN_KEY,
                refreshToken
            );
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.USER_KEY,
                JSON.stringify(user)
            );
        },
        logOut: (state) => {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;

            localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_KEY);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_KEY);
        },
    },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => state.auth.accessToken;
