import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PAYMENT_MADE } from '@utils/constants/purchase/payment-made.constants';

export const paymentMadeApi = createApi({
    reducerPath: 'paymentMadeApi',
    tagTypes: ['PaymentMade'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllPaymentMade: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: PAYMENT_MADE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        createPaymentMade: builder.mutation({
            query: (body) => ({
                url: PAYMENT_MADE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deletePaymentMade: builder.mutation({
            query: (id) => ({
                url: `${PAYMENT_MADE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllPaymentMadeQuery,
    useCreatePaymentMadeMutation,
    useDeletePaymentMadeMutation,
} = paymentMadeApi;
