export const PAYMENT_MADE = {
    BASE_PATH: '/purchase/payment-made/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    LIST_PAGE_TITLE: 'Payment Made List',
    EDIT_PAGE_TITLE: 'Edit Payment Made',
    GET_ALL: 'purchase-order/payment-made',
    GET_ONE: 'purchase-order/payment-made/edit/',
    CREATE: 'purchase-order/payment-made/create',
    UPDATE: 'purchase-order/payment-made/update/',
    DELETE: 'purchase-order/payment-made/delete/',
};
