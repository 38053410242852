export const EXPNESE = {
    BASE_PATH: '/expense-management/expense/',
    LIST_PAGE_TITLE: 'User Expense List',
    EDIT_PAGE_TITLE: 'Edit User Expense',
    GET_ALL: 'expense-management',
    GET_ONE: 'expense-management/edit/',
    CREATE: 'expense-management/create',
    UPDATE: 'expense-management/update/',
    DELETE: 'expense-management/delete/',
    ADD_NEW_TITLE: 'Add New User Expense',
};
