import { z } from 'zod';

export const consignmentSchema = z.object({
    sales_date: z.union([z.string(), z.date()], {
        message: 'sales date must be a valid date',
    }),
    customer_id: z.union([z.string(), z.number()]).optional(),
    consignment_contract_id: z.union([z.string(), z.number()]).optional(),
    payment_type_id: z.union([z.string(), z.number()]).optional(),
    payment_term_id: z.union([z.string(), z.number()]).optional(),
    remark: z.string().optional().optional(),
    description: z.string().optional().optional(),
    status : z.number().optional().optional(),
    reason : z.string().optional().optional(),
    product_detail: z.array(
        z.object({
            product_id: z.union([z.string(), z.number()]),
            unit_id: z.union([z.string(), z.number()]),
            sales_price: z.number(),
            qty: z.number(),
            total_amount: z.number(),
            product_code: z.string().optional(),
            product_name: z.string().optional(),
            unit_name: z.string().optional(),
            amount: z.number().optional().default(0),
            discount: z.number().optional().default(0),
            discount_amount: z.number().default(0),
            discount_type: z.string().optional().default('AMT'),
            tax: z.number().optional(),
            tax_amount: z.number().default(0),
            tax_type: z.string().optional().default('AMT'),
        })
    ),
    tax: z.number().optional().default(0),
    tax_type: z.string().optional(),
    tax_amount: z.number().default(0),
    discount: z.number().optional().default(0),
    discount_type: z.string().optional(),
    discount_amount: z.number().default(0),
    other_charges: z.number().optional().default(0),
    grand_total_amount: z.number().default(0),
    sub_total: z.number().default(0),
    current_amount: z.number().optional().nullable(),
    credit_balance: z.number().optional().nullable(),
});
export type ZConsignment = z.infer<typeof consignmentSchema>;


// export interface TConsignment extends ZConsignment {
//     consignment_id: number | string;
//     consignment_code: string;
//     customer_name: string;
//     payment_type_name: string;
//     status: string | number;
//     sales_date: string;
//     grand_total_amount: string;
// }