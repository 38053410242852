export const TRIP_SALES_RETURN = {
    BASE_PATH: '/trip-sales-return/sales-return/',
    BASE_PATH_MAKE_PAYMENT: '/trip-sales-return/make-payment/',
    MAKE_PAYMENT_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Tirp Sales Return',
    LIST_PAGE_TITLE: 'Tirp Sales Return List',
    EDIT_PAGE_TITLE: 'Edit Tirp Sales Return',
    GET_ALL: 'trip/sale-return',
    GET_ALL_MAKE_PAYMENTS: 'trip/sale-return/payment',
    GET_ONE: 'trip/sale-return/edit/',
    CREATE: 'trip/sale-return/create',
    UPDATE: 'trip/sale-return/update/',
    DELETE: 'trip/sale-return/delete/',
    DELETE_MAKE_PAYMENT: 'trip/sale-return/payment/delete/',
    MAKE_PAYMENT: 'trip/sale-return/payment/create/',
    ADD_NEW_TITLE: 'Add New Trip Sales Return',
};
