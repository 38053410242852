import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { USER_ROLE_ASSIGN } from '../../utils/constants/user-role-assign.constants';

export const userRoleAssignApi = createApi({
    reducerPath: 'userRoleAssignApi',
    tagTypes: ['UserRoleAssign'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllUserRoleAssigns: builder.query<
            [],
            {
                page: number;
                limit: number;
                channel_id?: string | number;
                user_role_id?: string | number;
            }
        >({
            query: ({ page = 1, limit = 10, channel_id, user_role_id }) => {
                const queryParams = {
                    url: USER_ROLE_ASSIGN.GET_ALL,
                    params: { page, limit },
                };

                if (channel_id) {
                    //@ts-ignore
                    queryParams.params.channel_id = channel_id;
                }

                if (user_role_id) {
                    //@ts-ignore
                    queryParams.params.user_role_id = user_role_id;
                }

                return queryParams;
            },
        }),
        getUserRoleAssign: builder.query<[], { id: string | number }>({
            query: ({ id }) => `${USER_ROLE_ASSIGN.GET_ONE}${id}`,
        }),
        getUserRoleAssignById: builder.query<[], { id: string | number }>({
            query: ({ id }) => `${USER_ROLE_ASSIGN.GET_BY_ID}${id}`,
        }),
        createUserRoleAssign: builder.mutation({
            query: (body) => ({
                url: USER_ROLE_ASSIGN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateUserRoleAssign: builder.mutation({
            query: ({ id, body }) => ({
                url: `${USER_ROLE_ASSIGN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteUserRoleAssign: builder.mutation({
            query: (id) => ({
                url: `${USER_ROLE_ASSIGN.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllUserRoleAssignsQuery,
    useGetUserRoleAssignQuery,
    useGetUserRoleAssignByIdQuery,
    useCreateUserRoleAssignMutation,
    useUpdateUserRoleAssignMutation,
    useDeleteUserRoleAssignMutation,
} = userRoleAssignApi;
