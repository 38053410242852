export const WAY_USER_ASSIGN = {
    BASE_PATH: '/distribution-region/way-user-assign/',
    GET_ALL: '/way-user-assign',
    GET_ONE: '/way-user-assign/edit/',
    GET_DETAIL: 'way-user-assign/detail/',
    CREATE: '/way-user-assign/create',
    UPDATE: '/way-user-assign/update/',
    DELETE: '/way-user-assign/delete/',
    ADD_NEW_TITLE: 'Add New Way User Assign',
};
