import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <div
            className="flex items-center mb-8 ml-2 mt-[-16px] text-sm text-primary-dark cursor-pointer w-fit"
            onClick={() => navigate(-1)}
        >
            <svg
                className="mr-3"
                xmlns="http://www.w3.org/2000/svg"
                height="18px"
                viewBox="0 -960 960 960"
                width="18px"
                fill="rgb(15,44,118)"
            >
                <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
            </svg>
            BACK
        </div>
    );
};

export default BackButton;
