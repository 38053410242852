import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import customStyles from '../styles/customStyles';
import DataTable from 'react-data-table-component';
import { searchModalColumns } from '@helpers/columns/form/search-modal-select.columns';
import CustomButton from '@components/buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@states/store';

interface SearchSelectModalProps {
    title: string;
    data: unknown[];
    single?: boolean;
    name: string;
    setValue: any;
    columnName: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    idName?: string;
    nameName?: string;
    action?: any;
    getValues?: any;
    setDisplayValue?: any;
    selectIds?: any[];
    selectId?: string | number;
    columns?: {
        name: string;
        columnName: string;
    }[];
}

const SearchSelectModal: React.FC<SearchSelectModalProps> = ({
    title,
    data,
    single,
    setValue,
    name,
    columnName,
    isOpen,
    setIsOpen,
    idName,
    nameName,
    action,
    getValues,
    setDisplayValue,
    selectIds,
    selectId,
    columns,
}) => {
    const [selected, setSelected] = useState([]);

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = ({ selectedRows }: any) => {
        setSelected(selectedRows);
    };

    const rowSelectCritera = useCallback(
        (row: any) => {
            const idColumnVal = row[idName || 'id'];

            if (single) {
                return idColumnVal == selectId;
            }

            const isSelected = selectIds?.find((si) => si == idColumnVal);
            // const isSelected = selectIds?.includes(idColumnVal) ?? false;

            return isSelected;
        },
        [single, idName, selectId, selectIds]
    );

    useEffect(() => {
        if (single) {
            setDisplayValue(selected[0] && selected[0][nameName || '']);
        } else {
            setDisplayValue(
                //@ts-ignore
                selectIds?.length > 0 ? `${selectIds?.length} selected` : ''
            );

            if (selectIds && selectIds.length > 0) {
                setValue(name, `${selectIds?.length} selected`, {
                    shouldValidate: true,
                });
            }
        }
    }, [selected, single, selectIds]);

    const handleClick = () => {
        if (single) {
            setValue(idName!, selected[0] && selected[0][idName || 'id']);

            if (action) {
                dispatch(action(selected.map((s: any) => s)));
            }
        } else {
            if (action) {
                dispatch(action(selected.map((s: any) => s)));

                setValue(name, `${selected?.length} selected`, {
                    shouldValidate: true,
                });
            }
        }

        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="relative h-full">
                <div className="flex justify-end">
                    <svg
                        onClick={() => setIsOpen(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 -960 960 960"
                        width="20px"
                        fill="#3e3e3e"
                        className="cursor-pointer"
                    >
                        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                    </svg>
                </div>
                <div className="py-4 px-14">
                    <div className="flex items-center justify-between mb-6">
                        <h3 className="text-xl font-[600] uppercase text-primary-dark">
                            {title}
                        </h3>
                        <div className="flex w-[33%] items-center justify-center ">
                            <div className="w-full">
                                <label className="relative block text-gray-400 focus-within:text-gray-600">
                                    <input
                                        // value={oldPassword}
                                        type="text"
                                        name="Search"
                                        id="search"
                                        placeholder="Search..."
                                        className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white rounded shadow-sm appearance-none border-[0.6px] border-primary form-input focus:outline-none"
                                    />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 -960 960 960"
                                        width="24px"
                                        fill="currentColor"
                                        className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                                    >
                                        <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="h-[410px] overflow-auto">
                        <DataTable
                            selectableRowSelected={rowSelectCritera}
                            columns={searchModalColumns(
                                idName || 'id',
                                //@ts-ignore
                                columns || []
                            )}
                            data={data}
                            selectableRows
                            onSelectedRowsChange={handleChange}
                            striped
                            selectableRowsSingle={single}
                        />
                    </div>
                    <div className="absolute bottom-4 right-16">
                        <CustomButton
                            handleClick={handleClick}
                            color="bg-primary-dark"
                            className="px-8 py-3"
                            title="Add"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SearchSelectModal;
