import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { UNIT_OF_MEASUREMENT } from '../../utils/constants/unit-of-measurement.constants';

export const unitOfMeasurementApi = createApi({
    reducerPath: 'unitOfMeasurementApi',
    tagTypes: ['UnitOfMeasurement'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllUnitOfMeasurements: builder.query<
            [],
            { page?: number; limit?: number }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: UNIT_OF_MEASUREMENT.GET_ALL,
                    params: { page, limit, status: 1 },
                };

                return queryParams;
            },
        }),
        createUnitOfMeasurement: builder.mutation({
            query: (body) => ({
                url: UNIT_OF_MEASUREMENT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateUnitOfMeasurement: builder.mutation({
            query: ({ id, body }) => ({
                url: `${UNIT_OF_MEASUREMENT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteUnitOfMeasurement: builder.mutation({
            query: (id) => ({
                url: `${UNIT_OF_MEASUREMENT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllUnitOfMeasurementsQuery,
    useCreateUnitOfMeasurementMutation,
    useUpdateUnitOfMeasurementMutation,
    useDeleteUnitOfMeasurementMutation,
} = unitOfMeasurementApi;
