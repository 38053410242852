import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "@components/layouts/PageWrapper";
import BackButton from "@components/buttons/BackButton";
import SaleTargetAllEdit from "@components/extra/sale-target/SaleTargetAllEdit";
import SaleTargetProductEdit from "@components/extra/sale-target/SaleTargetProductEdit";
import {
  useGetSaleTargetQuery,
  useUpdateSaleTargetMutation,
} from "@states/sale-target/sale-target.api";
import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
function AllTargetEditPage() {
  const { id } = useParams();

  const [target, setTarget] = useState(1);
  const { data: saleTargetData, isLoading } = useGetSaleTargetQuery({
    sales_target_id: id!,
  });
  const changeTarget = () => {
    setTarget((prevTarget) => (prevTarget === 1 ? 2 : 1));
  };
  useEffect(() => {
    if (saleTargetData?.data.sales_target_type_id == 1) {
      setTarget(1);
    } else {
      setTarget(2);
    }
  }, [saleTargetData]);
  const navigate = useNavigate();

  return (
    <PageWrapper>
      {isLoading && <LoadingSpinner />}
      <BackButton />
      <h3 className="mb-4 ml-2 text-2xl font-semibold text-primary-dark uppercase">
        Edit Sale All Target
      </h3>
      <hr className="mb-2" />
        <SaleTargetAllEdit saleTargetData={saleTargetData?.data} />
    </PageWrapper>
  );
}

export default AllTargetEditPage;
