export const CUSTOMER = {
    BASE_PATH: '/customer/customer/',
    GET_ALL: '/customer',
    GET_ONE: '/customer/edit/',
    CREATE: '/customer/create',
    UPDATE: '/customer/update/',
    DELETE: '/customer/delete/',
    ADD_NEW_TITLE: 'Add New Customer',
    LIST_TITLE: 'Customer List',
    EDIT_TITLE: 'Edit Customer',
};
