import { TPrimarySaleOrder } from '@helpers/validation-schemas/primary-sale-order/sale-order.schema';
import DeleteButton from '@components/buttons/DeleteButton';
import { thousandSeperator } from '@utils/general/digit-separators';
import { formatDate } from '@utils/general/format-date';

export const consignmentReceiveColumns = (
    deleteHandler: (id: string | number) => void
) => [
    {
        name: 'Payment Receive ID',
        selector: (row: any) => (
            <div className="italic font-semibold underline text-primary-dark">
                {row.consignment_payment_receive_id}
            </div>
        ),
        sort: true,
    },
    {
        name: 'Payment Receive Date',
        selector: (row:any ) =>
            formatDate(new Date(row.payment_receive_date)),
        sort: true,
    },
    {
        name: 'Invoice ID',
        selector: (row:any ) => row.consignment_invoice_code,
        sort: true,
    },
    {
        name: 'Payment Receive Amount',
        selector: (row: any) =>
            thousandSeperator(row.payment_receive_amount || 0),
        sort: true,
    },
    {
        name: 'Action',
        right: 'true',
        selector: (row: any) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <DeleteButton
                    id={row.consignment_payment_receive_id}
                    actionHandler={deleteHandler}
                />
            </div>
        ),
    },
];
