export const UNIT_OF_CONVERSION = {
    BASE_PATH: '/unit/unit-of-conversion/',
    GET_ALL: '/unit-conversion',
    CREATE: '/unit-conversion/create',
    EDIT: '/unit-conversion/edit/',
    UPDATE: '/unit-conversion/update/',
    DELETE: '/unit-conversion/delete/',
    ADD_NEW_TITLE: 'Add New Unit of Conversion',
    GET_BASE_UNIT: '/unit-conversion/get/',
};
