const OutlinedStatus = ({
    title,
    color,
    width = 'w-[11ch]',
}: {
    title: string;
    color: 'red' | 'green' | 'purple' | 'orange' | 'blue' | 'gray' | 'yellow';
    width?: string;
}) => {
    const colorStyles = {
        red: {
            textColor: 'text-red-700',
            bgColor: 'bg-red-100',
            borderColor: 'border border-red-700',
        },
        green: {
            textColor: 'text-green-700',
            bgColor: 'bg-green-100',
            borderColor: 'border border-green-700',
        },
        purple: {
            textColor: 'text-purple-700',
            bgColor: 'bg-purple-100',
            borderColor: 'border border-purple-700',
        },
        orange: {
            textColor: 'text-orange-700',
            bgColor: 'bg-orange-100',
            borderColor: 'border border-orange-700',
        },
        blue: {
            textColor: 'text-blue-700',
            bgColor: 'bg-blue-100',
            borderColor: 'border border-blue-700',
        },
        gray: {
            textColor: 'text-gray-700',
            bgColor: 'bg-gray-100',
            borderColor: 'border border-gray-700',
        },
        yellow: {
            textColor: 'text-yellow-700',
            bgColor: 'bg-yellow-100',
            borderColor: 'border border-yellow-700',
        },
    }[color];

    const classNames = `px-2 py-1 text-center text-[0.80rem] rounded ${width} ${colorStyles.textColor} ${colorStyles.bgColor} ${colorStyles.borderColor}`;

    return <div className={classNames}>{title}</div>;
};

export default OutlinedStatus;
