import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { CUSTOMER } from '../../utils/constants/customer.constants';

export const reportApi = createApi({
    reducerPath: 'reportApi',
    tagTypes: ['Report'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getSalesByProduct: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                from_date?: string;
                to_date?: string;
            }
        >({
            query: ({ page = 1, limit = 10, from_date, to_date }) => {
                const queryParams = {
                    url: 'reports/sales-by-products',
                    params: { page, limit },
                };

                if (from_date) {
                    //@ts-ignore
                    queryParams.params.from_date = from_date;
                }

                if (to_date) {
                    //@ts-ignore
                    queryParams.params.to_date = to_date;
                }

                return queryParams;
            },
        }),
        getSalesByProductCategory: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                from_date?: string;
                to_date?: string;
            }
        >({
            query: ({ page = 1, limit = 10, from_date, to_date }) => {
                const queryParams = {
                    url: 'reports/sales-by-product-categories',
                    params: { page, limit },
                };

                if (from_date) {
                    //@ts-ignore
                    queryParams.params.from_date = from_date;
                }

                if (to_date) {
                    //@ts-ignore
                    queryParams.params.to_date = to_date;
                }

                return queryParams;
            },
        }),
    }),
});

export const { useGetSalesByProductQuery, useGetSalesByProductCategoryQuery } =
    reportApi;
