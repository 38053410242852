export const DIRECT_SALES_RETURN = {
    BASE_PATH: '/direct-sales-return/sales-return/',
    BASE_PATH_MAKE_PAYMENT: '/direct-sales-return/make-payment/',
    MAKE_PAYMENT_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Direct Sales Return',
    LIST_PAGE_TITLE: 'Direct Sales Return List',
    EDIT_PAGE_TITLE: 'Edit Direct Sales Return',
    GET_ALL: 'direct-sale-return',
    GET_ALL_MAKE_PAYMENTS: 'direct-sale-return/payment',
    GET_ONE: 'direct-sale-return/edit/',
    CREATE: 'direct-sale-return/create',
    UPDATE: 'direct-sale-return/update/',
    DELETE: 'direct-sale-return/delete/',
    DELETE_MAKE_PAYMENT: 'direct-sale-return/payment/delete/',
    MAKE_PAYMENT: 'direct-sale-return/payment/update/',
    ADD_NEW_TITLE: 'Add New Direct Sales Return',
};
