export const CONSIGNMENT_CONTRACT = {
    BASE_PATH: '/consignment/consignment-contract/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },

    ADD_BUTTON_TITLE: 'Add New Consignment Contract',
    LIST_PAGE_TITLE: 'Consignment Contract List',
    EDIT_PAGE_TITLE: 'Edit Consignment Contract',
    GET_ALL: 'consignment/contract',
    GET_ONE: 'consignment/contract/edit/',
    CREATE: 'consignment/contract/create',
    UPDATE: 'consignment/contract/update/',
    DELETE: 'consignment/contract/delete/',
    ADD_NEW_TITLE: 'Add New Consignment Contract',
};
