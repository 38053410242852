export const PRIMARY_PAYMENT_RECEIVE = {
    BASE_PATH: '/primary-sale-order/payment-receive/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    LIST_PAGE_TITLE: 'Primary Sale Order List',
    EDIT_PAGE_TITLE: 'Edit Primary Sale Order',
    GET_ALL: 'primary-sale-order/payment-receive',
    GET_ONE: 'primary-sale-order/payment-receive/edit/',
    CREATE: 'primary-sale-order/payment-receive/create',
    UPDATE: 'primary-sale-order/payment-receive/update/',
    DELETE: 'primary-sale-order/payment-receive/delete/',
};
