import { MenuIconProps } from '@type-defs/general/TMenuIconProps';

const ProductIcon = (props: MenuIconProps) => {
    const { isRouted } = props;
    const color = isRouted ? '#ffffff' : '#6b7280';

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1952_145959)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 23L3 18.465V14.512L8.4 17.634L11.5 14.228V23ZM12.5 22.999V14.193L15.662 17.536L21 14.578V18.465L12.5 22.999ZM2.161 12.874L0 11.63L3 8.328L0 5.505L8.718 1L11.933 3.385L15.258 1L24 5.561L21.005 8.332L24 11.775L21.758 13.016L15.855 16.285L12.507 12.744L19.923 8.782L12.001 4.41L4.078 8.782L11.5 12.719V12.743L8.203 16.365L3 13.357L2.84 13.265L2.161 12.872V12.874Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1952_145959">
                    <rect width="24" height="24" fill={color} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ProductIcon;
