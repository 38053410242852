import React, { useState } from "react";
import PageWrapper from "@components/layouts/PageWrapper";
import BackButton from "@components/buttons/BackButton";
import SaleTargetAll from "../../../components/extra/sale-target/SaleTargetAll";

function AllTargetAddPage() {

  return (
    <PageWrapper>
      <BackButton />
      <h3 className="mb-4 ml-2 text-2xl font-semibold text-primary-dark uppercase">
        Add New Sale Target
      </h3>
      <SaleTargetAll />
    </PageWrapper>
  );
}

export default AllTargetAddPage;
