export const PURCHASE_ORDER = {
    BASE_PATH: '/purchase/purchase-order/',

    CONVERT_TO_BILL_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/convert-to-bill';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },

    ADD_BUTTON_TITLE: 'Add New Purchase Order',
    LIST_PAGE_TITLE: 'Purchase Order List',
    EDIT_PAGE_TITLE: 'Edit Purchase Order',
    GET_ALL: 'purchase-order',
    GET_ONE: 'purchase-order/edit/',
    CREATE: 'purchase-order/create',
    UPDATE: 'purchase-order/update/',
    DELETE: 'purchase-order/delete/',
    ADD_NEW_TITLE: 'Add New Purchase Order',
};
