import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { PRODUCT_TAG } from '../../utils/constants/product-tag.constants';

export const productTagApi = createApi({
    reducerPath: 'productTagApi',
    tagTypes: ['ProductTag'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllProductTags: builder.query<
            [],
            { page?: number; limit?: number; product_tag_name?: string }
        >({
            query: ({ page = 1, limit = 10, product_tag_name }) => {
                const queryParams = {
                    url: PRODUCT_TAG.GET_ALL,
                    params: { page, limit },
                };

                if (product_tag_name) {
                    //@ts-ignore
                    queryParams.params.product_tag_name = product_tag_name;
                }

                return queryParams;
            },
        }),
        createProductTag: builder.mutation({
            query: (body) => ({
                url: PRODUCT_TAG.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateProductTag: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRODUCT_TAG.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteProducutTag: builder.mutation({
            query: (id) => ({
                url: `${PRODUCT_TAG.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllProductTagsQuery,
    useCreateProductTagMutation,
    useUpdateProductTagMutation,
    useDeleteProducutTagMutation,
} = productTagApi;
