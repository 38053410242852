import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { UNIT_OF_CONVERSION } from '../../utils/constants/unit-of-conversion.constants';

export const unitOfConversionApi = createApi({
    reducerPath: 'unitOfConversionApi',
    tagTypes: ['UnitOfConversion'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllUnitOfConversions: builder.query<
            [],
            { page: number; limit: number }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: UNIT_OF_CONVERSION.GET_ALL,
                    params: { page, limit, status: 1 },
                };

                return queryParams;
            },
        }),
        getUnitOfConversion: builder.query<any, { id: string | number }>({
            query: ({ id }) => UNIT_OF_CONVERSION.EDIT + id,
        }),

        getBaseUnitOfConversion: builder.query<any, { id: string | number }>({
            query: ({ id }) => UNIT_OF_CONVERSION.GET_BASE_UNIT + id,
        }),
        createUnitOfConversion: builder.mutation({
            query: (body) => ({
                url: UNIT_OF_CONVERSION.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateUnitOfConversion: builder.mutation({
            query: ({ id, body }) => ({
                url: `${UNIT_OF_CONVERSION.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteUnitOfConversion: builder.mutation({
            query: (id) => ({
                url: `${UNIT_OF_CONVERSION.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllUnitOfConversionsQuery,
    useCreateUnitOfConversionMutation,
    useGetUnitOfConversionQuery,
    useLazyGetUnitOfConversionQuery,
    useLazyGetBaseUnitOfConversionQuery,
    useUpdateUnitOfConversionMutation,
    useDeleteUnitOfConversionMutation,
} = unitOfConversionApi;
