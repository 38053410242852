const ExpandIcon = () => {
    return (
        <div className="flex items-center justify-center w-full h-full wtf">
            <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#002C76"
                />
                <path
                    d="M7 12H17"
                    stroke="#002C76"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    );
};

export default ExpandIcon;
