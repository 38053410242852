import DataTable from 'react-data-table-component';

type TableEmptyArgs = {
    columnNames?: string[];
};

const TableEmpty = ({ columnNames = [] }: TableEmptyArgs) => {
    return (
        <DataTable
            responsive
            striped
            //@ts-ignore
            columns={columnNames.map((c) => ({
                name: c,
            }))}
            data={[{ foo: 'bar' }]}
        />
    );
};

export default TableEmpty;
