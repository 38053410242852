export const STOCK_IMPORT = {
    BASE_PATH: '/stock-and-inventory/stock-import/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Stock Import',
    LIST_PAGE_TITLE: 'Stock Import List',
    EDIT_PAGE_TITLE: 'Edit Stock Import',
    GET_ALL: 'inventory-import',
    GET_ONE: 'inventory-import/edit/',
    CREATE: 'inventory-import',
    UPDATE: 'inventory-import/update/',
    DELETE: 'inventory-import/delete/',
    ADD_NEW_TITLE: 'Add New Stock Import',
};
