import { thousandSeperator } from '@utils/general/digit-separators';
import { formatDateTime } from '@utils/general/fromat-date-time';

export const consignmentPaymentHistoryColumns = () => [
    {
        name: 'Payment Receive ID',
        selector: (row: any) => (
            <div className="font-semibold text-primary-dark">
                {row.payment_receive_code}
            </div>
        ),
        // center: true,
    },
    {
        name: 'Payment Receive Date',
        selector: (row: any) =>
            formatDateTime(new Date(row.payment_receive_date) || new Date()),
        center: true,
    },
    {
        name: 'Payment Receive Amount',
        selector: (row: any) => thousandSeperator(row.payment_receive_amount),
        center: true,
    },
];

export const primaryPaymentHistoryColumnNames = ['Credit Limit Amount', 'Date'];
