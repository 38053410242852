import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { TRIP_PAYMENT_RECEIVE } from '@utils/constants/trip/trip-payment-receive';

export const tripPaymentReceiveApi = createApi({
    reducerPath: 'tripPaymentReceiveApi',
    tagTypes: ['TripPaymentReceive'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllTripPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: TRIP_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        createTripPaymentReceive: builder.mutation({
            query: (body) => ({
                url: TRIP_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deleteTripPaymentReceive: builder.mutation({
            query: (id) => ({
                url: `${TRIP_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllTripPaymentReceivesQuery,
    useCreateTripPaymentReceiveMutation,
    useDeleteTripPaymentReceiveMutation,
} = tripPaymentReceiveApi;
