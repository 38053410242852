import React from 'react';

interface CustomButtonProps {
    title: string;
    color?: string;
    textColor?: string;
    handleClick?: () => void;
    disabled?: boolean;
    type?: string;
    isLarge?: boolean;
    isMedium?: boolean;
    className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
    title,
    color,
    textColor,
    handleClick,
    disabled,
    type,
    isLarge,
    isMedium,
    className,
}) => {
    return (
        <button
            //@ts-ignore
            type={type ?? 'button'}
            disabled={disabled}
            onClick={type === 'submit' ? undefined : handleClick}
            className={`${color ?? 'bg-primary'} ${textColor ?? 'text-white'} ${
                isLarge ? 'px-28 py-4' : isMedium ? 'px-12 py-4' : 'py-2 px-3'
            } rounded text-sm outline outline-[0.5px] outline-gray-300 ${className} ${
                disabled && 'opacity-50'
            }`}
        >
            {title}
        </button>
    );
};

export default CustomButton;
