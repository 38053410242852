import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { PRIMARY_PAYMENT_RECEIVE } from '@utils/constants/primary-sale-payment-receive.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const primaryPaymentReceiveApi = createApi({
    reducerPath: 'primaryPaymentReceiveApi',
    tagTypes: ['PrimaryPaymentReceive'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllPrimaryPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: PRIMARY_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        createPrimaryPaymentReceive: builder.mutation({
            query: (body) => ({
                url: PRIMARY_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deletePrimaryPaymentReceive: builder.mutation({
            query: (id) => ({
                url: `${PRIMARY_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllPrimaryPaymentReceivesQuery,
    useCreatePrimaryPaymentReceiveMutation,
    useDeletePrimaryPaymentReceiveMutation,
} = primaryPaymentReceiveApi;
