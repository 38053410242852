import { useGetAllPaymentTypesQuery } from '@states/common/common.api';

const useGetAllPaymentTypes = () => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch,
        isFetching,
    } = useGetAllPaymentTypesQuery();

    const paymentTypeResponse = rawData as any as {
        payment_type_id: string;
        payment_type_name: string;
    }[];

    return {
        paymentTypeData: paymentTypeResponse,
        paymentTypeError: error,
        paymentTypeLoading: isLoading,
        paymentTypeRefetch: refetch,
        paymentTypeFetching: isFetching,
    };
};

export default useGetAllPaymentTypes;
