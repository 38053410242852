export const STOCK_TRANSFER = {
    BASE_PATH: '/warehouse-and-inventory/stock-transfer/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Stock Transfer',
    LIST_PAGE_TITLE: 'Stock Transfer List',
    EDIT_PAGE_TITLE: 'Edit Stock Transfer',
    GET_ALL: 'inventory-transfer',
    GET_ONE: 'inventory-transfer/edit/',
    CREATE: 'inventory-transfer/create',
    UPDATE: 'inventory-transfer/update/',
    DELETE: 'inventory-transfer/delete/',
    ADD_NEW_TITLE: 'Add New Stock Transfer',
};
