import React from 'react';

const CollaspseIcon = () => {
    return (
        <div className="flex items-center justify-center w-full h-full wtf">
            <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#002C76"
                />
                <path
                    d="M16.9993 12.8307H12.8327V16.9974C12.8327 17.2184 12.7449 17.4304 12.5886 17.5867C12.4323 17.7429 12.2204 17.8307 11.9993 17.8307C11.7783 17.8307 11.5664 17.7429 11.4101 17.5867C11.2538 17.4304 11.166 17.2184 11.166 16.9974V12.8307H6.99935C6.77834 12.8307 6.56637 12.7429 6.41009 12.5867C6.25381 12.4304 6.16602 12.2184 6.16602 11.9974C6.16602 11.7764 6.25381 11.5644 6.41009 11.4081C6.56637 11.2519 6.77834 11.1641 6.99935 11.1641H11.166V6.9974C11.166 6.77638 11.2538 6.56442 11.4101 6.40814C11.5664 6.25186 11.7783 6.16406 11.9993 6.16406C12.2204 6.16406 12.4323 6.25186 12.5886 6.40814C12.7449 6.56442 12.8327 6.77638 12.8327 6.9974V11.1641H16.9993C17.2204 11.1641 17.4323 11.2519 17.5886 11.4081C17.7449 11.5644 17.8327 11.7764 17.8327 11.9974C17.8327 12.2184 17.7449 12.4304 17.5886 12.5867C17.4323 12.7429 17.2204 12.8307 16.9993 12.8307Z"
                    fill="#002C76"
                />
            </svg>
        </div>
    );
};

export default CollaspseIcon;
