import { Outlet } from 'react-router-dom';
import Header from '../components/layouts/Header';
import Sidebar from '../components/layouts/Sidebar';

const RootPage = () => {
    return (
        <div className="w-full h-screen">
            <div className="flex">
                <Sidebar />
                <div className="w-[83%] h-[max-content]">
                    <Header />
                    <div className="px-8 py-8 bg-gray-100">
                        {/* the routed component will get rendered as the outlet */}
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RootPage;
