export const TRIP_SALES_ORDER = {
    BASE_PATH: '/trip/sales-order/',
    CONVERT_TO_INVOICE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/convert-to-invoice';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Trip Sals Order',
    LIST_PAGE_TITLE: 'Trip Sales Order List',
    EDIT_PAGE_TITLE: 'Edit Trip Sales Order',
    GET_ALL: 'trip/sale',
    GET_ONE: 'trip/sale/edit/',
    CREATE: 'trip/sale/create',
    UPDATE: 'trip/sale/update/',
    APPROVE: 'trip/order-approval/update/',
    DELETE: 'trip/sale/delete/',
    ADD_NEW_TITLE: 'Add New Trip Sales Order',
};
