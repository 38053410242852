import { z } from 'zod';

export const consignmentReturnSchema = z.object({
    consignment_invoice_id: z.union([z.string(), z.number()]),
    customer_id: z.union([z.string(), z.number()]).optional(),
    return_date: z.union([z.string(), z.date()], {
        message: 'Return date must be a valid date',
    }),
    sub_total: z.number().default(0),
    other_charges: z.number().optional().default(0),
    grand_total_amount: z.number().default(0),
    return_deduct: z.number().optional().default(0),
    return_deduct_method: z.string(),
    return_deduct_amount: z.number().optional(),
    total_return_amount: z.number().optional(),
    balance: z.number().optional(),
    remark: z.string().optional(),
    description: z.string().optional(),
    product_detail: z.array(
        z.object({
            product_id: z.union([z.string(), z.number()]),
            unit_id: z.union([z.string(), z.number()]),
            sales_price: z.number(),
            qty: z.number(),
            total_amount: z.number(),
            product_code: z.string().optional(),
            product_name: z.string().optional(),
            unit_name: z.string().optional(),
            amount: z.number().optional().default(0),
            discount: z.number().optional().default(0),
            discount_amount: z.number().default(0).optional(),
            discount_type: z.string().optional().default('AMT').optional(),
            tax: z.number().optional(),
            tax_amount: z.number().default(0).optional(),
            tax_type: z.string().optional().default('AMT').optional(),
            return_qty: z.number(),
            return_amount: z.number(),
        })
    ),
});

export type ZConsignmentReturn = z.infer<typeof consignmentReturnSchema>;

export interface TConsignmentReturn extends ZConsignmentReturn {
    consignment_return_id: string | number;
    consignment_return_code: string;
    customer_name: string;
    status: number | string;
}
