import { useState } from 'react';

interface SearchProps {
    setSearch?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Search: React.FC<SearchProps> = ({ setSearch }) => {
    const [keyword, setKeyword] = useState('');
    const [hasSearched, setHasSearched] = useState(false);

    const handleSearch = () => {
        if (keyword.length > 0) {
            setHasSearched(!hasSearched);
            setSearch && setSearch(keyword.trim());
        }
    };

    const handleClearSearch = () => {
        setSearch && setSearch(undefined);
        setKeyword('');
        setHasSearched(!hasSearched);
    };

    return (
        <div className="flex justify-end items start">
            <div className="w-1/4 mb-7">
                <label className="relative block text-gray-400 focus-within:text-gray-600">
                    <input
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        type="text"
                        name="Search"
                        id="search"
                        placeholder="Search"
                        className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white border border-gray-200 rounded-md shadow-sm appearance-none form-input focus:outline-none"
                    />
                    {keyword.length > 0 && hasSearched ? (
                        <svg
                            onClick={handleClearSearch}
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                            className="absolute w-5 h-5 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                        >
                            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                        </svg>
                    ) : (
                        <svg
                            onClick={handleSearch}
                            xmlns="http://www.w3.org/2000/svg"
                            height="22px"
                            viewBox="0 -960 960 960"
                            width="22px"
                            fill="currentColor"
                            className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                        >
                            <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                        </svg>
                    )}
                </label>
            </div>
        </div>
    );
};

export default Search;
