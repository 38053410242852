type What = {
    name: string;
    columnName: string;
}[];

export const searchModalChooseColumns = (
    idName: string,
    arr: What,
    onClickHandler: (id: number | string) => void
) => {
    let columns = arr.map((item: any) => {
        return {
            name: item.columnName,
            selector: (row: any) => row[item.name],
            sortable: true,
        };
    });

    const actionColumn = {
        name: 'Action',
        selector: (row: any) => (
            <button
                onClick={() => onClickHandler(row[idName])}
                className="px-3 py-1 text-sm text-gray-100 rounded bg-primary-dark"
            >
                Choose
            </button>
        ),
        right: 'true',
    };

    // @ts-ignore
    columns.push(actionColumn);

    return columns;
};
