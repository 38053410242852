import React from 'react';
import { ActionButtonProps } from '../../types/general/TActionButtonProps';

const DeleteButton: React.FC<ActionButtonProps> = ({
    fill,
    id,
    actionHandler,
    disabled,
}) => {
    return (
        <svg
            className={`${disabled ? 'opacity-50 cursor-not-allowed' : ''} `}
            onClick={disabled ? () => {} : () => actionHandler(id)}
            style={{
                cursor: 'pointer',
            }}
            width="24"
            height="24"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M18.2369 11.7489L11.7612 18.2246M18.2389 18.2285L11.7578 11.7461"
                stroke="#DA2037"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.8568 2.5H9.14189C5.05946 2.5 2.5 5.39054 2.5 9.48108V20.5189C2.5 24.6095 5.0473 27.5 9.14189 27.5H20.8554C24.9514 27.5 27.5 24.6095 27.5 20.5189V9.48108C27.5 5.39054 24.9514 2.5 20.8568 2.5Z"
                stroke="#DA2037"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DeleteButton;
