import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { BUSINESS_UNIT } from '../../utils/constants/business-unit.constants';

export const businessUnitApi = createApi({
    reducerPath: 'businessUnitApi',
    tagTypes: ['BusinessUnit'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllBusinessUnits: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                business_unit_name?: string;
                customer_id?: string | number;
            }
        >({
            query: ({
                page = 1,
                limit = 100,
                business_unit_name,
                customer_id,
            }) => {
                const queryParams = {
                    url: BUSINESS_UNIT.GET_ALL,
                    params: { page, limit },
                };

                if (business_unit_name) {
                    //@ts-ignore
                    queryParams.params.business_unit_name = business_unit_name;
                }

                if (customer_id) {
                    //@ts-ignore
                    queryParams.params.customer_id = customer_id;
                }

                return queryParams;
            },
        }),
        createBusinessUnit: builder.mutation({
            query: (body) => ({
                url: BUSINESS_UNIT.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateBusinessUnit: builder.mutation({
            query: ({ id, body }) => ({
                url: `${BUSINESS_UNIT.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteBusinessUnit: builder.mutation({
            query: (id) => ({
                url: `${BUSINESS_UNIT.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllBusinessUnitsQuery,
    useCreateBusinessUnitMutation,
    useUpdateBusinessUnitMutation,
    useDeleteBusinessUnitMutation,
} = businessUnitApi;
