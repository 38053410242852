import {useGetConsingmentQuery} from '@states/consignment/consignment.api'

const useGetOneConsignment = (id: string | number) => {
    const {
        data: rawData,
        error,
        isLoading,
    } = useGetConsingmentQuery({
        consignment_id: id!,
    });
   
    const consignmentResponse =
        rawData as any;
    return {
        consignmentData: consignmentResponse?.data,
        consignmentMessage: consignmentResponse?.message,
        consignmentSuccess: consignmentResponse?.success,
        consignmentError: error,
        consignmentLoading: isLoading,
    };
};

export default useGetOneConsignment;
