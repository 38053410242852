export const PRODUCT = {
    BASE_PATH: '/product/product-information/',
    GET_ALL: '/product',
    GET_BY_CATEGORIES: '/product/categories',
    GET_BY_BARCODE: '/product/barcode/',
    EDIT: '/product/edit/',
    CREATE: '/product/create',
    UPDATE: '/product/update/',
    DELETE: '/product/delete/',
    ADD_NEW_TITLE: 'Add New Product',
};
