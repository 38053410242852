import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import PageWrapper from '@components/layouts/PageWrapper';
import Search from '@components/layouts/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import usePagination from '@hooks/usePagination';
import useSearch from '@hooks/useSearch';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import { useDidMountEffect } from '@hooks/useDidMountEffect';
import { DeletePopup } from '@helpers/popups/DeletePopup';
import TableSkeleton from '@components/layouts/TableSkeleton';
import { TDeleteSuccesResponse } from '@type-defs/general/TDeleteSuccessResponse';
import { Toast } from '@helpers/popups/Toast';
import {
    useDeletePrimaryInvoiceMutation,
    useGetAllPrimaryInvoicesQuery,
} from '@states/primary-sale-order/primary-invoice.api';
import {useGetAllConsignmentInvoicesQuery,useDeleteConsignmentInvoiceMutation} from '@states/consignment/consignment-invoice.api'
import { invoiceColumns } from '@helpers/columns/consignment/invoice-columns';
import { PRIMARY_INVOICE } from '@utils/constants/primary-sale-invoice.constants';
import { TPrimaryInvoice } from '@helpers/validation-schemas/primary-sale-order/invoice.schema';
import {CONSIGNMENT_INVOICE} from '@utils/constants/consignment/consignment-invoice.constants'
const ConsignmentInvoicePage = () => {
    const { page, limit, handlePageChange, handlePerRowsChange } =
        usePagination();
    const { search, setSearch } = useSearch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data,
        isFetching,
        error: getAllError,
        refetch,
    } = useGetAllConsignmentInvoicesQuery({
        page,
        limit,
    });
   
  
    const [deleteConsignmentInvoice, { error: deleteError, isLoading }] =useDeleteConsignmentInvoiceMutation();
        
    // a custom hook that will handle fetch/mutation errors
    useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

    useDidMountEffect(() => {
        refetch();
    }, [page, limit, search]);

    useEffect(() => {
        if (location.state?.reload) {
            refetch();
        }
    }, [location.state?.reload]);

    const editHandler = (invoice: any) => {
        //TODO: backend needs to provide sales_date in GET ONE response
        navigate(
            `${CONSIGNMENT_INVOICE.BASE_PATH}${invoice.consignment_invoice_id}/edit`,
            {
                state: {
                    sales_date: invoice.sales_date,
                },
            }
        );
    };

    const deleteHandler = async (id: number | string) => {
        try {
            const { isConfirmed } = await DeletePopup.fire();

            if (isConfirmed) {
                const res = await deleteConsignmentInvoice(id);

                const successData = 'data' in res ? res.data : null;
                const { success, message } =
                    successData as TDeleteSuccesResponse;

                if (success) {
                    Toast.fire({
                        icon: 'success',
                        title: message,
                    });

                    refetch();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Search setSearch={setSearch} />
            <PageWrapper title="Invoice List">
                <DataTable
                    keyField="primary_sales_invoice_id"
                    responsive
                    striped
                    pagination
                    paginationServer
                    //@ts-ignore
                    paginationTotalRows={data?.paginator?.totalItems}
                    progressPending={isFetching}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={<TableSkeleton row={10} col={3} />}
                    progressComponent={<TableSkeleton row={10} col={3} />}
                    //@ts-ignore
                    columns={invoiceColumns(editHandler, deleteHandler)}
                    //@ts-ignore
                    data={data?.data}
                />
            </PageWrapper>
        </React.Fragment>
    );
};

export default ConsignmentInvoicePage;
