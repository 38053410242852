import BackButton from '@components/buttons/BackButton';
import CustomDateField from '@components/form/CustomDateField';
import CustomInputField from '@components/form/CustomInputField';
import CustomSearchModalChooseField from '@components/form/CustomSearchModalChooseField';
import CustomSelectField from '@components/form/CustomSelectField';
import CustomTextAreaField from '@components/form/CustomTextAreaField';
import PageWrapper from '@components/layouts/PageWrapper';
import useGetAllWarehouses from '@hooks/warehouse-and-inventory/useGetAllWarehouses';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';
import { thousandSeperator } from '@utils/general/digit-separators';
import { productDetailColumns } from '@helpers/columns/primary-sale-order/product-detail.columns';
import { useCallback, useEffect, useState } from 'react';
import { addFormValues, resetFormValues } from '@states/common/common.slice';
import {
    ZInvoice,
    invoiceSchema,
} from '@helpers/validation-schemas/primary-sale-order/invoice.schema';
import {
    consignmentproductDetailColumns,
    consignmentproductDetailColumnNames,
} from '@helpers/columns/consignment/consignment-detail.columns';
import {ZInvoiceConsignment,invoiceConsignmentSchema} from '@helpers/validation-schemas/consignment/invoice.schema'
import CustomButton from '@components/buttons/CustomButton';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    useDeletePrimaryInvoiceMutation,
    useUpdatePrimaryInvoiceMutation,
} from '@states/primary-sale-order/primary-invoice.api';
import {useUpdateConsignmentInvoiceMutation,useDeleteConsignmentInvoiceMutation} from '@states/consignment/consignment-invoice.api'
import { Toast } from '@helpers/popups/Toast';
import { PRIMARY_INVOICE } from '@utils/constants/primary-sale-invoice.constants';
import {CONSIGNMENT_INVOICE} from '@utils/constants/consignment/consignment-invoice.constants'
import useGetOnePrimarySaleorderInvoice from '@hooks/primary-sales-order/useGetOnePrimarySaleOrderInvoice';
import { calculatePercentage } from '@utils/general/cal-percent';
import { ConfirmDeletePopup } from '@helpers/popups/ConfirmDeletePopup';
import useGetOneConsignmentInvoice from '@hooks/consignment/useGetOneConsignmentInvoice'
import ConsignmentPaymentReceiveHistoryModal from '@components/modals/consignment/ConsignmentPaymentReceiveHistoryModal';
const ConsignmentInvoiceEditPage = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { formValues } = useSelector((state: RootState) => state.common);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    const { consignmentInvoiceData } = useGetOneConsignmentInvoice(id!);
    const [deleteConsignmentInvoice, { error: deleteError, isLoading }] =useDeleteConsignmentInvoiceMutation();

    const defaultValues = {
        consignment_id: consignmentInvoiceData?.consignment_id,
        consignment_code: consignmentInvoiceData?.consignment_code,
        sales_date:
            consignmentInvoiceData?.sales_date || location.state?.sales_date,
        consignment_invoice_id: consignmentInvoiceData?.consignment_invoice_id,
        consignment_invoice_code:
            consignmentInvoiceData?.consignment_invoice_code,
        customer_id: consignmentInvoiceData?.customer_id,
        payment_type_id: consignmentInvoiceData?.payment_type_id,
        payment_terms_id: consignmentInvoiceData?.payment_terms_id,
        invoice_date: consignmentInvoiceData?.invoice_date,
        due_date: consignmentInvoiceData?.due_date,
        customer_name:
            consignmentInvoiceData?.customer_first_name +
            ' ' +
            consignmentInvoiceData?.customer_last_name,
        warehouse_id: consignmentInvoiceData?.warehouse_id,
        warehouse_name: consignmentInvoiceData?.warehouse_name,
        product_detail: consignmentInvoiceData?.products,
        sub_total: consignmentInvoiceData?.sub_total,
        grand_total_amount: consignmentInvoiceData?.grand_total_amount,
        balance: consignmentInvoiceData?.balance,
        discount_amount: consignmentInvoiceData?.discount_amount,
        tax_amount: consignmentInvoiceData?.tax_amount,
        other_charges: consignmentInvoiceData?.other_charges,
        discount_type: consignmentInvoiceData?.discount_type,
        tax_type: consignmentInvoiceData?.tax_type,
        discount:
            consignmentInvoiceData?.discount_type !== 'AMT'
                ? calculatePercentage(
                      consignmentInvoiceData?.grand_total_amount,
                      consignmentInvoiceData?.discount_amount
                  )
                : consignmentInvoiceData?.discount_amount,
        tax:
            consignmentInvoiceData?.tax_type !== 'AMT'
                ? calculatePercentage(
                      consignmentInvoiceData?.grand_total_amount,
                      consignmentInvoiceData?.tax_amount
                  )
                : consignmentInvoiceData?.tax_amount,
    };

   
    const {
        register,
        handleSubmit,
        getValues,
        setError,
        watch,
        setValue,
        control,
        formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
        reset,
    } = useForm<ZInvoiceConsignment>({
        defaultValues,
        resolver: zodResolver(invoiceConsignmentSchema),
    });

    const { warehouseData } = useGetAllWarehouses({});
    const [
        updateConsignmentInvoice,
        { isSuccess, data, reset: updateReset },
    ] = useUpdateConsignmentInvoiceMutation();

    const [
        deletePrimarySaleOrderIncoice,
        { data: deleteInvoiceData, isSuccess: deleteInvoiceIsSuccess },
    ] = useDeletePrimaryInvoiceMutation({});

    useEffect(() => {
        if (
            (isSuccess && data) ||
            (deleteInvoiceIsSuccess && deleteInvoiceData)
        ) {
            Toast.fire({
                title:
                    data.message ||
                    deleteInvoiceData.message ||
                    'Updated the data.',
                icon: 'success',
            });

            navigate(PRIMARY_INVOICE.BASE_PATH, {
                state: {
                    reload: true,
                },
            });
        }

        return () => updateReset();
    }, [
        isSuccess,
        data,
        deleteInvoiceData,
        deleteInvoiceIsSuccess,
        navigate,
        Toast,
    ]);

    useEffect(() => {
        const pd = defaultValues?.product_detail?.map((dtl: any) => ({
            product_id: dtl.product_id,
            product_code: dtl.product_code,
            product_name: dtl.product_name,
            unit_name: dtl.unit_name,
            unit_id: dtl.unit_id,
            sales_price: dtl.sales_price,
            qty: dtl.qty,
            amount: dtl.amount,
            discount_type: dtl.discount_type,
            discount_amount: dtl.discount_amount,
            tax_type: dtl.tax_type,
            tax_amount: dtl.tax_amount,
            total_amount: dtl.total_amount,
        }));

        dispatch(addFormValues({ ...defaultValues, product_detail: pd }));

        reset({ ...defaultValues, product_detail: pd });
    }, [consignmentInvoiceData]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const pd = value?.product_detail?.map((dtl) => ({ ...dtl }));

            dispatch(addFormValues({ ...value, product_detail: pd }));
        });

        return () => {
            subscription.unsubscribe();
            dispatch(resetFormValues());
            updateReset();
        };
    }, [watch, dispatch]);

    const handleRemove = (index: number) => {};

    const deleteHandler = async () => {
        try {
            const { isConfirmed } = await ConfirmDeletePopup.fire({
                preConfirm: async (reason) => {
                    try {
                        //TODO:
                        return reason;
                    } catch (error) {
                        console.log(error);
                    }
                },
            });

            if (isConfirmed) {
                await deleteConsignmentInvoice(id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit: SubmitHandler<ZInvoiceConsignment> = async (data) => {
        try {
            // async stuff here
            console.log(data, 'data');
            await asyncDispatcher(data);
        } catch (error: any) {
            setError('root', {
                message: error.message,
            });
        }
    };

    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                const {
                    product_detail,
                    tax,
                    discount,
                    customer_name,
                    ...rest
                } = reqBody;
              
                const formattedProductDetails = product_detail?.map(
                    (pd: any) => ({
                        product_id: pd.product_id,
                        unit_id: pd.unit_id,
                        sales_price: pd.sales_price,
                        qty: pd.qty,
                        amount: pd.amount,
                        discount_type: pd.discount_type,
                        discount_amount: pd.discount_amount,
                        tax_type: pd.tax_type,
                        tax_amount: pd.tax_amount,
                        total_amount: pd.total_amount,
                    })
                );
                delete rest.sales_date
                rest.consignment_id = formValues?.consignment_id
                await updateConsignmentInvoice({
                    id,
                    body: {
                        ...rest,
                        // balance: rest.grand_total_amount,
                        products: formattedProductDetails,
                    },
                });
            } catch (error) {
                console.log('Error', error);
            }
        },
        [updateConsignmentInvoice, formValues, id]
    );

    return (
        <PageWrapper>
            <BackButton />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-center justify-between mb-4">
                    <h3 className="ml-2 text-2xl font-[600] text-primary-dark uppercase">
                        Edit Invoice
                    </h3>
                    <CustomButton
                        handleClick={() => {
                            navigate(
                                CONSIGNMENT_INVOICE.MAKE_PAYMENT_RECEIVE_PATH(id!),
                                {
                                    state: {
                                        carriedFormValues: formValues,
                                    },
                                }
                            );
                        }}
                        color="bg-primary-dark"
                        title="Make Payment Receive"
                        className="px-8 py-4 text-lg font-semibold"
                    />
                </div>
                <div className="grid grid-cols-6 gap-x-4 gap-y-8">
                    <CustomInputField
                        disabled
                        label="Inovoice Id"
                        errors={errors}
                        name={'consignment_invoice_code'}
                        placeHolder="Enter invoice id"
                        register={register}
                    />
                     <CustomInputField
                        disabled
                        label="Consignment Id"
                        errors={errors}
                        name={'consignment_code'}
                        // placeHolder="Enter  id"
                        register={register}
                    />
                    {/* <CustomInputField
                        disabled
                        label="Manual Inovoice Id"
                        errors={errors}
                        name={'manual_invoice_id'}
                        placeHolder="Enter manual invoice id"
                        register={register}
                    /> */}
                    <CustomDateField
                        disabled
                        errors={errors}
                        name="invoice_date"
                        label="Invoice Date"
                        register={register}
                        //@ts-ignore
                        control={control}
                    />
                    <CustomDateField
                        errors={errors}
                        name="due_date"
                        label="Due Date"
                        register={register}
                        //@ts-ignore
                        control={control}
                    />
                    <CustomInputField
                        disabled
                        label="Customer Name"
                        errors={errors}
                        name={'customer_name'}
                        placeHolder="Enter customer name"
                        register={register}
                    />
                    <CustomSelectField
                        errors={errors}
                        name="payment_type_id"
                        label="Payment Type"
                        disabled
                        //@ts-ignore
                        control={control}
                        options={[
                            {
                                label: 'Credit',
                                value: 1,
                            },
                            { label: 'Consignment', value: 2 },
                            { label: 'Cashdown', value: 3 },
                        ]}
                        value={formValues?.payment_type_id}
                    />
                    <ConsignmentPaymentReceiveHistoryModal
                        isOpen={isHistoryOpen}
                        setIsOpen={setIsHistoryOpen}
                        //@ts-ignore
                        data={consignmentInvoiceData?.payment_receive_data}
                    />
                    <CustomInputField
                        label="Payment History"
                        placeHolder="0"
                        withEditIcon={false}
                        register={register}
                        name="history"
                        errors={errors}
                        setValue={setValue}
                        withHistory
                        setShowHistoryModal={setIsHistoryOpen}
                        value={
                            consignmentInvoiceData?.payment_receive_data
                                ? consignmentInvoiceData?.payment_receive_data.reduce(
                                    //@ts-ignore
                                      (acc, cur) => {
                                          return (
                                              acc + cur.payment_receive_amount
                                          );
                                      },
                                      0
                                  )
                                : 0
                        }
                    />
                    {/* <CustomSearchModalChooseField
                        key={'warehouse_id'}
                        errors={errors}
                        colSpan={2}
                        disabled
                        name={'warehouse_id'}
                        label="Warehouse Name"
                        title={'Warehouse List'}
                        columns={[
                            {
                                name: 'warehouse_name',
                                columnName: 'Warehouse Name',
                            },
                            {
                                name: 'warehouse_address',
                                columnName: 'Address',
                            },
                        ]}
                        register={register}
                        placeHolder="Choose warehouse name"
                        setValue={setValue}
                        data={warehouseData?.map((warehouse) => ({
                            warehouse_id: warehouse.warehouse_id,
                            warehouse_name: warehouse.warehouse_name,
                            warehouse_address: warehouse.warehouse_address,
                        }))}
                        columnName={'Warehouse Name'}
                        idName={'warehouse_id'}
                        nameName={'warehouse_name'}
                        value={formValues?.warehouse_name}
                    /> */}
                    <div></div>
                    <div></div>
                    {/* <CustomTextAreaField
                        key={'remark'}
                        colSpan={3}
                        errors={errors}
                        name="remark"
                        register={register}
                    /> */}
                    <CustomTextAreaField
                        key={'description'}
                        errors={errors}
                        colSpan={6}
                        name="description"
                        register={register}
                    />
                    <div className="flex justify-between col-span-6">
                        <h3 className="ml-2 font-[600] text-primary-dark">
                            <span className=" text-[1.1rem]">
                                Add Primary Sale Order Detail
                            </span>
                        </h3>
                    </div>
                    <div className="flex flex-col justify-between col-span-6">
                        <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                            <span className=" text-[1.3rem]">
                                Product Detail
                            </span>
                        </h3>
                    </div>
                    <div className="-mt-4 col-span-full">
                        <DataTable
                            className="pb-4"
                            responsive
                            striped
                            //@ts-ignore
                            columns={consignmentproductDetailColumns(
                                errors,
                                register,
                                setValue,
                                handleRemove,
                                control,
                                true
                            )}
                            //@ts-ignore
                            data={formValues?.product_detail?.map((f, i) => ({
                                ...f,
                                index: i,
                            }))}
                        />

                        {formValues?.product_detail?.length > 0 && (
                            <div className="bg-zinc-100 opacity-[0.6] rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                                <div className="flex items-center mb-4">
                                    <h5 className="text-[1.05rem] basis-3/12">
                                        Sub Total
                                    </h5>
                                    <span className="s basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12"></div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.sub_total || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Discount
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            disabled
                                            defaultValue={0}
                                            classNames="-mr-3 w-[130px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'discount'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                        <select
                                            disabled
                                            {...register('discount_type')}
                                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                        >
                                            <option value="AMT">AMT</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        -
                                        {thousandSeperator(
                                            formValues.discount_amount || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Tax
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            disabled
                                            defaultValue={0}
                                            classNames="-mr-3 w-[130px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'tax'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                        <select
                                            disabled
                                            {...register('tax_type')}
                                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                        >
                                            <option value="AMT">AMT</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.tax_amount || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-4">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Other Charges
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            disabled
                                            defaultValue={0}
                                            classNames="-mr-3 w-[200px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'other_charges'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.other_charges || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className="text-xl font-semibold basis-3/12">
                                        Grand Total
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12"></div>
                                    <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                                        {thousandSeperator(
                                            formValues.grand_total_amount || 0
                                        )}
                                    </h5>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ml-2 mt-14">
                    <CustomButton
                        isLarge
                        title="Cancel"
                        color="white"
                        textColor="gray.800"
                        className="mr-10"
                        handleClick={() => navigate(-1)}
                    />
                    <CustomButton
                        handleClick={handleSubmit(onSubmit)}
                        isLarge
                        title={isSubmitting ? 'Loading...' : 'Submit'}
                        disabled={isSubmitting}
                        type="submit"
                    />
                    <CustomButton
                        type="button"
                        handleClick={deleteHandler}
                        isLarge
                        className="float-right"
                        color="bg-red-600"
                        title={'Delete'}
                    />
                </div>
                {errors.root && (
                    <div className="mt-6 text-red-500">
                        {errors.root.message}
                    </div>
                )}
            </form>
        </PageWrapper>
    );
};

export default ConsignmentInvoiceEditPage;
