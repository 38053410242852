export const PURCHASE_BILL = {
    BASE_PATH: '/purchase/bill/',
    MAKE_PAYMENT_RECEIVE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/made-payment';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Purchase Order Bill',
    LIST_PAGE_TITLE: 'Purchase Bill List',
    EDIT_PAGE_TITLE: 'Edit Bill',
    GET_ALL: 'purchase-order/bill',
    GET_ONE: 'purchase-order/bill/edit/',
    CREATE: 'purchase-order/bill/create',
    UPDATE: 'purchase-order/bill/update/',
    DELETE: 'purchase-order/bill/delete/',
    ADD_NEW_TITLE: 'Add New Purchase Bill',
};
