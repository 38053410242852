import React from 'react';
import { ActionButtonProps } from '../../types/general/TActionButtonProps';

const EditButton: React.FC<ActionButtonProps> = ({
    fill,
    id,
    actionHandler,
    disabled,
}) => {
    return (
        <svg
            className={`${disabled ? 'opacity-50 cursor-not-allowed' : ''} `}
            onClick={disabled ? () => {} : () => actionHandler(id)}
            style={{
                marginRight: '8px',
                cursor: 'pointer',
            }}
            width="24"
            height="24"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M14.365 3.48633H9.69125C5.8475 3.48633 3.4375 6.20758 3.4375 10.0601V20.4526C3.4375 24.3051 5.83625 27.0263 9.69125 27.0263H20.7212C24.5775 27.0263 26.9763 24.3051 26.9763 20.4526V15.4176"
                stroke="#002C76"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8107 12.1253L20.1213 3.77901C21.1566 2.74033 22.8347 2.74033 23.8701 3.77901L25.2235 5.13823C26.2588 6.17803 26.2588 7.86449 25.2235 8.90317L16.8729 17.2897C16.4203 17.7442 15.8064 18 15.1658 18H11L11.1045 13.7783C11.1201 13.1573 11.3725 12.5654 11.8107 12.1253Z"
                stroke="#002C76"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19 5L24 10"
                stroke="#002C76"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M9 19.5H21" stroke="#002C76" strokeLinecap="round" />
        </svg>
    );
};

export default EditButton;
