export const STOCK_BALANCE = {
    BASE_PATH: '/warehouse-and-inventory/stock-balance/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Stock Import',
    LIST_PAGE_TITLE: 'Stock Import List',
    EDIT_PAGE_TITLE: 'Edit Stock Import',
    GET_ALL: 'stock-balance',
    ADD_NEW_TITLE: 'Add New Stock Import',
};
