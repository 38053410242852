import Swal from 'sweetalert2';

export const Toast = Swal.mixin({
    position: 'top-right',
    toast: true,
    showCancelButton: false,
    showConfirmButton: false,
    text: '',
    timerProgressBar: true,
    timer: 1500,
    icon: 'success',
});
