import { useState } from 'react';

const useSearch = () => {
    const [search, setSearch] = useState<string | undefined>(undefined);

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    return {
        search,
        setSearch,
        handleSearch,
    };
};

export default useSearch;
