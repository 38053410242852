import CustomForm from '@components/form/CustomForm';
import PageWrapper from '@components/layouts/PageWrapper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { supplierFields } from '@helpers/fields/supplier/supplier.fields';
import { useUpdateSupplierMutation } from '@states/supplier/supplier.api';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import { removeKeys } from '@utils/general/removeKeys';
import { Toast } from '@helpers/popups/Toast';
import { SUPPLIER } from '@utils/constants/supplier.constant';
import { useGetAllStatesQuery } from '@states/common/common.api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';
import {
    addCities,
    addStates,
    addTownships,
} from '@states/common/common.slice';
import { supplierSchema } from '@helpers/validation-schemas/supplier/supplier.schema';
import { LoadingSpinner } from '@components/layouts/LoadingSpinner';
import useRegionEffect from '@hooks/distribution-region/useRegionEffect';

const SupplierEditPage = () => {
    const { id } = useParams();

    const location = useLocation();
    const navigate = useNavigate();
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const {
        states: storedStates,
        cities: storedCities,
        townships: storedTownships,
    } = useSelector((state: RootState) => state.common);

    const { supplier } = location.state as {
        supplier: any;
    };


    const {
        cities,
        townships,
        // regionDetail,
        isLoading: regionLoading,
    } = useRegionEffect();
    

    const shallowSupplier = useMemo(
        () => ({
            ...supplier,
            state_id: supplier.supplier_division_id,
            city_id: supplier.supplier_city_id,
            township_id: supplier.supplier_township_id,
        }),
        [supplier]
    );

    const [updateSupplier, { isLoading, error: updateError, isSuccess }] =
        useUpdateSupplierMutation();
    const { data: stateData } = useGetAllStatesQuery();

    useErrorHandling(...castTypeArr<TFailResponse>([updateError]));

    // Reminder: Don't forget to update the dependencies array.
    
    const fields = useMemo(() => {
        return supplierFields({ 
            divisionValue: shallowSupplier.sup_state_name,
            cityValue: shallowSupplier.sup_city_name,
            townshipValue: shallowSupplier.sup_town_ship_name,
            // stored data
            stateData,
            stateSelectAction: addStates,
            cities,
            citySelectAction: addCities,
            townships,
            townshipSelectAction: addTownships,
            // selector ids
            divisionSelectId:
                storedStates[0]?.state_id || shallowSupplier.state_id,
            citySelectId: storedCities[0]?.city_id || shallowSupplier.city_id,
            townshipSelectId:
                storedTownships[0]?.city_id || shallowSupplier.township_id,
            
        });
    }, [
        supplier,
        stateData,
        cities,
        townships,
        storedStates,
        storedCities,
        storedTownships,
        shallowSupplier,
    ]);


    useEffect(() => {
        if (shallowSupplier) {
            const { state_id, supplier_state_name, city_id, supplier_city_name } =
            shallowSupplier;

            const stateToAdd = { state_id, name: supplier_state_name };
            const cityToAdd = { city_id, name: supplier_city_name };

            dispatch(addStates([stateToAdd]));
            dispatch(addCities([cityToAdd]));
        }

        return () => {
            dispatch(addStates([]));
            dispatch(addCities([]));
            dispatch(addTownships([]));
        };
    }, []);


   

  

    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                console.log(reqBody)
                const rest = removeKeys(
                    reqBody,
                    'state_id',
                    'state_name',
                    'city_id',
                    'city_name',
                    'township_id',
                    'township_name'
                );
                console.log("Fdf",{ 
                    ...rest,
                    supplier_division_id: reqBody.state_id,
                    supplier_city_id: reqBody.city_id,
                    supplier_township_id: reqBody.township_id
                })
                const res = await updateSupplier({
                    id,
                    body: { 
                        ...rest,
                        supplier_division_id: reqBody.state_id,
                        supplier_city_id: reqBody.city_id,
                        supplier_township_id: reqBody.township_id
                    },
                });

                const successData = 'data' in res ? res.data : null;
                const { success, message } = successData;

                if (success || isSuccess) {
                    Toast.fire({
                        title: message,
                        icon: 'success',
                    });

                    navigate(`/supplier-management${SUPPLIER.BASE_PATH}`, {
                        state: {
                            reload: true,
                        },
                    });
                }
            } catch (error) {
                console.log('Error', error);
            }
        },
        [updateSupplier, isSuccess, Toast, navigate]
    );

    return (
        <PageWrapper>
            {isLoading && <LoadingSpinner />}
            <CustomForm
                title={supplier.EDIT_TITLE}
                schema={supplierSchema}
                defaultValues={shallowSupplier}
                fields={fields}
                asyncDispatcher={asyncDispatcher}
            />
        </PageWrapper>
    );
};

export default SupplierEditPage;
