export const formatDate = (date: Date) => {
    const options = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    };

    //@ts-ignore
    return date.toLocaleString('en-US', options);
};
