import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import {CONSIGNMENT_PAYMENT_RECEIVE} from '@utils/constants/consignment/consignment-payment-receive.constants'

export const consignmentPaymentReceiveApi = createApi({
    reducerPath: 'consignmentPaymentReceiveApi',
    tagTypes: ['ConsignmentPaymentReceive'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllConsignmentPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: CONSIGNMENT_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        createConsignmentPaymentReceive: builder.mutation({
            query: (body) => ({
                url: CONSIGNMENT_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deleteConsignmentPaymentReceive: builder.mutation({
            query: (id) => ({
                url: `${CONSIGNMENT_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllConsignmentPaymentReceivesQuery,
    useCreateConsignmentPaymentReceiveMutation,
    useDeleteConsignmentPaymentReceiveMutation,
} = consignmentPaymentReceiveApi;
