export const supplierFields = ({
    stateData,
    stateSelectAction,
    cities,
    citySelectAction,
    townships,
    townshipSelectAction,
    cityDisabled,
    townshipDisabled,
    
    divisionValue,
    cityValue,
    townshipValue,
    divisionSelectId,
    citySelectId,
    townshipSelectId,
}: {
    stateData?: any[];
    stateSelectAction?: any;
    cities?: any[];
    citySelectAction?: any;
    townships?: any[];
    townshipSelectAction?: any;
    cityDisabled?: boolean;
    townshipDisabled?: boolean;
    creditTypeValue?: string | number;
    divisionValue?: string;
    cityValue?: string;
    townshipValue?: string;
    divisionSelectId?: string | number;
    citySelectId?: string | number;
    townshipSelectId?: string | number;
   
    setShowHistoryModal?: any;
}) => {
    const states = stateData?.map((sd) => ({
        stateid: sd.stateid,
        name: sd.name,
    }));

    return [
        {
            name: 'supplier_name',
            type: 'text',
            label: 'Supplier Name',
            required: true,
        },
       
        {
            name: 'supplier_phone1',
            type: 'text',
            label: 'Phone Number',
            required: true,
        },
        {
            name: 'supplier_email',
            type: 'text',
            label: 'Supplier Email',
            showLabelOptional: true,
            placeHolder: 'Enter supplier email',
        },
        {
            name: 'contact_phoneno',
            type: 'text',
            label: 'Contact Person Phone Number',
            showLabelOptional: true,
            placeHolder: 'Enter phone number',
        },
        {
            name: 'contact_first_name',
            type: 'text',
            label: 'Contact Person First Name',
            showLabelOptional: true,
            placeHolder: 'Enter first name',
        },
        {
            name: 'contact_last_name',
            type: 'text',
            label: 'Contact Person Last Name',
            showLabelOptional: true,
            placeHolder: 'Enter last name',
        },
        {
            name: 'contact_email',
            type: 'text',
            label: 'Contact Person Email',
            showLabelOptional: true,
            placeHolder: 'Enter email',
        },
        {
            name: 'Add supplier Detail',
            type: 'secondary-title',
        },
        {
            name: 'state_name',
            title: 'Division/State List',
            columnName: 'Division/State Name',
            idName: 'state_id',
            nameName: 'name',
            type: 'search-modal',
            label: 'State/Division Name',
            placeHolder: 'Select State/Division',
            data: states?.map((s) => ({
                state_id: s.stateid,
                name: s.name,
            })),
            single: true,
            action: stateSelectAction,
            selectId: divisionSelectId,
            columns: [
                {
                    name: 'name',
                    columnName: 'State/Division Name',
                },
            ],
            value: divisionValue,
            required: true,
        },
        {
            name: 'city_name',
            title: 'City List',
            columnName: 'City Name',
            idName: 'city_id',
            nameName: 'cityname',
            type: 'search-modal',
            label: 'City Name',
            placeHolder: 'Select City Name',
            data: cities?.map((c) => ({
                city_id: c.cityid,
                cityname: c.cityname,
                state_id: c.state_id,
                state_name: c.state_name,
            })),
            action: citySelectAction,
            selectId: citySelectId,
            single: true,
            disabled: cityDisabled,
            columns: [
                {
                    name: 'cityname',
                    columnName: 'City Name',
                },
                {
                    name: 'state_name',
                    columnName: 'State/Division Name',
                },
            ],
            value: cityValue,
            required: true,
        },
        {
            name: 'township_name',
            title: 'Township List',
            columnName: 'Township Name',
            idName: 'township_id',
            nameName: 'townshipname',
            type: 'search-modal',
            label: 'Township Name',
            placeHolder: 'Select Township Name',
            data: townships?.map((t) => ({
                township_id: t.townshipid,
                townshipname: t.townshipname,
                city_id: t.city_id,
                city_name: t.city_name,
                state_id: t.state_id,
                state_name: t.state_name,
            })),
            single: true,
            disabled: townshipDisabled,
            action: townshipSelectAction,
            selectId: townshipSelectId,
            columns: [
                {
                    name: 'townshipname',
                    columnName: 'Township Name',
                },
                {
                    name: 'city_name',
                    columnName: 'City Name',
                },
                {
                    name: 'state_name',
                    columnName: 'State/Division Name',
                },
            ],
            value: townshipValue,
            required: true,
        },
        {
            name: 'supplier_address',
            type: 'text',
            label: 'Address',
            colSpan: 6,
            required: true,
        },
       
       
        {
            name: 'remark',
            type: 'textarea',
            colSpan: 3,
        },
        {
            name: 'description',
            type: 'textarea',
            colSpan: 3,
        },
    ];
};
