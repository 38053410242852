export const TRIP_MANAGEMENT = {
    BASE_PATH: '/trip/trip-management/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Trip',
    LIST_PAGE_TITLE: 'Trip List',
    EDIT_PAGE_TITLE: 'Edit Trip',
    GET_ALL: '/trip',
    GET_ONE: '/trip/edit/',
    CREATE: '/trip/create',
    UPDATE: '/trip/update/',
    DELETE: '/trip/delete/',
    ADD_NEW_TITLE: 'Add New Trip',
};
