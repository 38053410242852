import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import PageWrapper from '@components/layouts/PageWrapper';
import Search from '@components/layouts/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import usePagination from '@hooks/usePagination';
import useSearch from '@hooks/useSearch';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import TableSkeleton from '@components/layouts/TableSkeleton';
import { TDeleteSuccesResponse } from '@type-defs/general/TDeleteSuccessResponse';
import { Toast } from '@helpers/popups/Toast';
import {
    useGetAllConsignmentReturnsQuery,
    useDeleteConsignmentReturnMutation,
} from '@states/consignment-sales-return /consignment-return.api';

import { CONSIGNMENT_RETURN } from '@utils/constants/consignment-sales-return /consignment-return.constants';
import { consignmentReturnColumns } from '@helpers/columns/consignment-return/consignment-return.columns';
import NoRecord from '@components/layouts/NoRecord';
import { ConfirmDeletePopup } from '@helpers/popups/ConfirmDeletePopup';

const ConsignmentReturnPage = () => {
    const { page, limit, handlePageChange, handlePerRowsChange } =
        usePagination();
    const { search, setSearch } = useSearch();
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data,
        isFetching,
        error: getAllError,
        refetch,
    } = useGetAllConsignmentReturnsQuery({
        page,
        limit,
        customer_first_name: search,
    });
    const [deleteConsignmentReturn, { error: deleteError, isLoading }] =
        useDeleteConsignmentReturnMutation();

    useErrorHandling(...castTypeArr<TFailResponse>([getAllError, deleteError]));

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (location.state?.reload) {
            refetch();
        }
    }, [location.state?.reload]);

    const editHandler = (consignment_return_id: string | number) => {
        navigate(
            `${CONSIGNMENT_RETURN.BASE_PATH}${consignment_return_id}/edit`
        );
    };

    const deleteHandler = async (id: number | string) => {
        try {
            await ConfirmDeletePopup.fire({
                confirmButtonText: 'Delete',
                showLoaderOnConfirm: true,
                inputValidator: (value) => {
                    return !value && 'You need to write something!';
                },
                preConfirm: async (reason) => {
                    try {
                        if (reason) {
                            const res = await deleteConsignmentReturn({
                                id,
                                body: {
                                    delete_reason: reason,
                                },
                            });

                            const successData = 'data' in res ? res.data : null;
                            const { success, message } =
                                successData as TDeleteSuccesResponse;

                            if (success) {
                                Toast.fire({
                                    icon: 'success',
                                    title: message,
                                });

                                refetch();
                            }
                        }
                        //TODO:
                        return reason;
                    } catch (error) {
                        console.log(error);
                    }
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <Search setSearch={setSearch} />
            <PageWrapper
                addPath={CONSIGNMENT_RETURN.ADD_PATH()}
                addButtonTitle={CONSIGNMENT_RETURN.ADD_BUTTON_TITLE}
                title={CONSIGNMENT_RETURN.LIST_PAGE_TITLE}
            >
                <DataTable
                    keyField="consignment_return_id"
                    responsive
                    striped
                    pagination
                    paginationServer
                    //@ts-ignore
                    paginationTotalRows={data?.paginator?.totalItems}
                    progressPending={isFetching}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={<NoRecord />}
                    progressComponent={<TableSkeleton row={10} col={7} />}
                    //@ts-ignore
                    columns={consignmentReturnColumns(
                        editHandler,
                        deleteHandler
                    )}
                    //@ts-ignore
                    data={data?.data}
                />
            </PageWrapper>
        </React.Fragment>
    );
};

export default ConsignmentReturnPage;
