const NoRecord = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full mt-20 text-center text-gray-600">
            <p className="mb-2 mr-1 text-gray-500">
                There are no records to display.{' '}
            </p>
            <button
                className="flex items-center justify-center"
                onClick={() => window.location.reload()}
            >
                <p className="text-primary"> Reload the page </p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#746D69"
                >
                    <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                </svg>
            </button>
        </div>
    );
};

export default NoRecord;
