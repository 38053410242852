import { MenuIconProps } from '@type-defs/general/TMenuIconProps';

const ExpenseManagementIcon = (props: MenuIconProps) => {
    const { isRouted } = props;
    const color = isRouted ? '#ffffff' : '#6b7280';

    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1952_145623)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.9498 1.41204V0.307804C8.0998 0.075528 8.4698 -0.249659 8.7498 0.307804V1.41809C11.9977 1.63917 14.5901 4.23536 14.7877 7.47519H16.0498C16.2165 7.60792 16.4498 7.96297 16.0498 8.32134H14.7845C14.5635 11.538 11.981 14.1087 8.7498 14.3287V15.3892C8.5998 15.638 8.2198 15.9865 7.8998 15.3892V14.3319C4.62017 14.1337 1.9971 11.5146 1.81043 8.24654H0.649798C0.449798 8.08897 0.169798 7.7141 0.649798 7.47519H1.81191C2.01151 4.20218 4.65526 1.58606 7.9498 1.41204ZM8.4 11.7558C10.5539 11.7558 12.3 10.0176 12.3 7.87342C12.3 5.72927 10.5539 3.99109 8.4 3.99109C6.24609 3.99109 4.5 5.72927 4.5 7.87342C4.5 10.0176 6.24609 11.7558 8.4 11.7558Z"
                    fill={color}
                />
                <rect y="16.7324" width="3.2" height="7.26693" fill={color} />
                <rect
                    x="5.2002"
                    y="19.7188"
                    width="3.2"
                    height="4.28052"
                    fill={color}
                />
                <rect
                    x="10.4004"
                    y="17.5293"
                    width="3.2"
                    height="6.47056"
                    fill={color}
                />
                <rect
                    x="15.5996"
                    y="14.1445"
                    width="3.2"
                    height="9.85516"
                    fill={color}
                />
                <rect
                    x="20.7998"
                    y="11.3574"
                    width="3.2"
                    height="12.6425"
                    fill={color}
                />
                <path
                    d="M9.54386 6.78713C9.24843 6.48376 9.26661 5.8705 8.66172 5.8705C8.38868 5.85252 8.03162 5.8705 7.96861 6.24793C7.94761 6.55348 8.09463 6.78713 9.35483 7.11065C9.7749 7.21849 10.4764 7.74691 10.426 8.56649C10.405 8.97988 10.1236 9.82822 9.1658 9.91449V10.238C9.03978 10.4357 8.67433 10.7125 8.22066 10.238V9.91449C7.8426 9.78867 7.14947 9.53705 7.02347 8.94393C7.00054 8.72825 6.92266 8.30767 7.27551 8.13513C7.50655 8.06324 7.8776 8.13513 8.03162 8.72825C8.15764 9.21353 8.70665 9.57566 9.1658 9.05177C9.41784 8.7642 9.69508 8.13513 8.78774 7.91945C8.19965 7.82958 7.02347 7.4126 7.02347 6.46361C7.00247 6.05023 7.2125 5.20189 8.22066 5.11562V4.73818C8.36768 4.55844 8.76254 4.30682 9.1658 4.73818L9.1658 5.0617C9.58586 5.16954 10.426 5.62246 10.426 6.57145C10.426 6.78713 9.85891 7.11065 9.54386 6.78713Z"
                    fill={color}
                    stroke={color}
                    stroke-width="0.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_1952_145623">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ExpenseManagementIcon;
