import DeleteButton from '@components/buttons/DeleteButton';
import EditButton from '@components/buttons/EditButton';

export const supplierColumns = (
    editHandler: (supplier: any) => void,
    deleteHandler: (id: string | number) => void
) => [
    {
        name: 'Supplier Name',
        selector: (row: any) => (
            <div className=" text-primary-dark">{row.supplier_name}</div>
        ),
    },
    {
        name: 'Phone Number',
        selector: (row: any) => (
            <div className="text-primary-dark">{row.supplier_phone1}</div>
        ),
    },
    {
        name: 'Address',
        selector: (row: any) => row.supplier_address,
    },

    {
        name: 'Action',
        right: 'true',
        selector: (row: any) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <EditButton
                    id={row.supplier_id}
                    actionHandler={() => editHandler(row)}
                />
                <DeleteButton
                    id={row.supplier_id}
                    actionHandler={deleteHandler}
                />
            </div>
        ),
    },
];
