import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { TRIP_USER_ASSIGN } from '@utils/constants/trip/trip-user-assign.constants';

export const tripUserAssignApi = createApi({
    reducerPath: 'tripUserAssignApi',
    tagTypes: ['TripUserAssign'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllTripUserAssigns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                trip_name?: string;
                username?: string;
            }
        >({
            query: ({ page = 1, limit = 10, trip_name, username }) => {
                const queryParams = {
                    url: TRIP_USER_ASSIGN.GET_ALL,
                    params: { page, limit },
                };

                if (trip_name) {
                    //@ts-ignore
                    queryParams.params.trip_name = trip_name;
                }
                if (username) {
                    //@ts-ignore
                    queryParams.params.username = username;
                }

                return queryParams;
            },
        }),
        getTripUserAssign: builder.query<any, { user_id: string | number }>({
            query: ({ user_id }) => TRIP_USER_ASSIGN.GET_ONE + user_id,
        }),
        getTripUserAssignDetail: builder.query<
            any,
            { user_id: string | number }
        >({
            query: ({ user_id }) => TRIP_USER_ASSIGN.GET_DETAIL + user_id,
        }),
        createTripUserAssign: builder.mutation({
            query: (body) => ({
                url: TRIP_USER_ASSIGN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateTripUserAssign: builder.mutation({
            query: ({ id, body }) => ({
                url: `${TRIP_USER_ASSIGN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteTripUserAssign: builder.mutation({
            query: (id) => ({
                url: `${TRIP_USER_ASSIGN.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllTripUserAssignsQuery,
    useCreateTripUserAssignMutation,
    useUpdateTripUserAssignMutation,
    useDeleteTripUserAssignMutation,
    useGetTripUserAssignQuery,
    useLazyGetTripUserAssignDetailQuery,
} = tripUserAssignApi;
