import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { CUSTOMER } from '../../utils/constants/customer.constants';

export const customerApi = createApi({
    reducerPath: 'customerApi',
    tagTypes: ['Customer', 'CustomerCreditLimitHistory'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllCustomers: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
                customer_type_id?: number;
                customer_category_id?: number;
            }
        >({
            query: ({
                page = 1,
                limit = 10,
                customer_first_name,
                customer_type_id,
                customer_category_id,
            }) => {
                const queryParams = {
                    url: CUSTOMER.GET_ALL,
                    params: { page, limit },
                };

                if (customer_first_name) {
                    //@ts-ignore
                    queryParams.params.customer_first_name =
                        customer_first_name;
                }
                if (customer_type_id) {
                    //@ts-ignore
                    queryParams.params.customer_type_id = customer_type_id;
                }
                if (customer_category_id) {
                    //@ts-ignore
                    queryParams.params.customer_category_id =
                        customer_category_id;
                }

                return queryParams;
            },
        }),
        getCustomer: builder.query<any, { id: string | number }>({
            query: ({ id }) => {
                return `${CUSTOMER.GET_ONE}${id}`;
            },
        }),
        getAllContractCustomers: builder.query<any, void>({
            query: () => `consignment/contract/customer`,
        }),
        getCustomerAllCreditLimitHistory: builder.query<
            [],
            {
                id: string | number;
            }
        >({
            query: ({ id }) => `common/customer-credit-limit/${id}`,
            providesTags: ['CustomerCreditLimitHistory'],
        }),
        createCustomer: builder.mutation({
            query: (body) => ({
                url: CUSTOMER.CREATE,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Customer'],
        }),
        updateCustomer: builder.mutation({
            query: ({ id, body }) => ({
                url: `${CUSTOMER.UPDATE}/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'CustomerCreditLimitHistory', id: arg.id },
            ],
        }),
        deleteCustomer: builder.mutation({
            query: (id) => ({
                url: `${CUSTOMER.DELETE}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Customer'],
        }),
    }),
});

export const {
    useGetAllCustomersQuery,
    useCreateCustomerMutation,
    useGetCustomerQuery,
    useLazyGetCustomerQuery,
    useGetAllContractCustomersQuery,
    useUpdateCustomerMutation,
    useDeleteCustomerMutation,
    useGetCustomerAllCreditLimitHistoryQuery,
} = customerApi;
