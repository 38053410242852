export const PRIMARY_INVOICE = {
    BASE_PATH: '/primary-sale-order/invoice/',
    MAKE_PAYMENT_RECEIVE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment-receive';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Primary Sale Order Invoice',
    LIST_PAGE_TITLE: 'Primary Sale Order List',
    EDIT_PAGE_TITLE: 'Edit Primary Sale Order',
    GET_ALL: 'primary-sale-order/invoice',
    GET_ONE: 'primary-sale-order/invoice/edit/',
    CREATE: 'primary-sale-order/invoice',
    UPDATE: 'primary-sale-order/invoice/update/',
    DELETE: 'primary-sale-order/invoice/delete/',
    ADD_NEW_TITLE: 'Add New Primary Sale Order Invoice',
};
