import Swal from 'sweetalert2';

export const ConfirmDeletePopup = Swal.mixin({
    confirmButtonText: 'Deactivate',
    title: 'Confirmation',
    html: `<p>Please make sure you want to delete this record. 
                Proceeding means the data will be soft deleted. 
                Please provide the reason below.</p>`,
    input: 'textarea',
    showCancelButton: true,
    confirmButtonColor: '#fca5a5',
    cancelButtonColor: '#808080',
    buttonsStyling: false,
    inputAutoFocus: false,
    focusConfirm: false,
    customClass: {
        confirmButton:
            'w-40 px-2 py-[0.75rem] font-semibold foucs:outline-red-4000 text-red-600 bg-red-200 rounded-md mx-2',
        cancelButton:
            'w-40 px-2 py-[0.75rem] font-semibold rounded-md bg-gray-50 mx-2',
        title: 'text-red-700 text-xl float-left',
    },
});
