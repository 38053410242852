export const CONSIGNMENT_PAYMENT_RECEIVE = {
    BASE_PATH: '/consignment/payment-receive/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    LIST_PAGE_TITLE: 'Consignment  List',
    EDIT_PAGE_TITLE: 'Edit Consignment ',
    GET_ALL: 'consignment/payment-receive',
    GET_ONE: 'consignment/payment-receive/edit/',
    CREATE: 'consignment/payment-receive/create',
    UPDATE: 'consignment/payment-receive/update/',
    DELETE: 'consignment/payment-receive/delete/',
};
