import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { useGetAllStatesQuery } from '@states/common/common.api';

const useRegionEffect = () => {
    const [cities, setCities] = useState<any[]>([]);
    const [townships, setTownships] = useState<any[]>([]);

    const { data: stateData, isLoading } = useGetAllStatesQuery();
    const {
        states,
        cities: storedCities,
        townships: storedTownships,
    } = useSelector((state: RootState) => state.common);

    useEffect(() => {
        if (states.length > 0) {
            const selectedStates = stateData?.filter((state: any) =>
                states
                    .map((s: any) => parseInt(s.state_id))
                    .includes(state.stateid)
            );

            const stateCities = selectedStates?.map((state: any) =>
                state.cities.map((c: any) => ({
                    ...c,
                    state_id: state.stateid,
                    state_name: state.name,
                }))
            );

            setCities(stateCities?.flat(1) || []);
        }
    }, [states, stateData]);

    useEffect(() => {
        if (storedCities.length > 0) {
            const yCityIds = storedCities.map((item: any) =>
                parseInt(item.city_id)
            );

            const filteredCities = cities.filter((c) =>
                yCityIds.includes(c.cityid)
            );

            const cityTownships = filteredCities?.map((city) =>
                city.townships?.map((t: any) => ({
                    ...t,
                    state_id: city.state_id,
                    state_name: city.state_name,
                    city_id: city.cityid,
                    city_name: city.cityname,
                }))
            );

            setTownships(
                cityTownships?.flat(1).filter((t) => t !== undefined) || []
            );
        }
    }, [storedCities, cities, stateData, states]);

    return { cities, townships, isLoading };
};

export default useRegionEffect;
