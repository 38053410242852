import DeleteButton from '@components/buttons/DeleteButton';
import EditButton from '@components/buttons/EditButton';
import { thousandSeperator } from '@utils/general/digit-separators';
import { TPrimarySalesReturn } from '@helpers/validation-schemas/primary-sales-return/sales-return.schema';
import { formatDate } from '@utils/general/format-date';
import OutlinedStatus from '@components/status/OutlinedStatus';

export const consignmentReturnColumns = (
    editHandler: (id: string | number) => void,
    deleteHandler: (id: string | number) => void
) => [
    {
        name: 'Consignment Return ID',
        selector: (row: any) => (
            <div className="font-semibold text-primary-dark">
                {row.consignment_return_code}
            </div>
        ),
        sort: true,
    },
    {
        name: 'Consignment Invoice ID',
        selector: (row: any) => row.consignment_invoice_code,
        sort: true,
    },
    {
        name: 'Return Date',
        selector: (row: any) => formatDate(new Date(row.return_date)),
        sort: true,
    },
    {
        name: 'Customer Name',
        selector: (row: any) => row.customer_name || row.customer_first_name,
        sort: true,
    },
    {
        name: 'Status',
        selector: (row: any) => (
            <OutlinedStatus
                width="w-[12ch]"
                color={
                    row.status == 1
                        ? 'blue'
                        : row.status == 2
                        ? 'purple'
                        : row.status == 3
                        ? 'green'
                        : 'red'
                }
                title={
                    row.status == 1
                        ? 'Open'
                        : row.status == 2
                        ? 'Partial Paid'
                        : row.status == 3
                        ? 'Paid'
                        : 'Closed'
                }
            />
        ),
        sort: true,
    },
    {
        name: 'Total Return Amount',
        selector: (row: any) => thousandSeperator(row.grand_total_amount),
        sort: true,
    },
    {
        name: 'Action',
        right: 'true',
        selector: (row: any) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <EditButton
                    disabled={row.status == 3}
                    id={row.consignment_return_id}
                    actionHandler={() => editHandler(row.consignment_return_id)}
                />
                <DeleteButton
                    id={row.consignment_return_id}
                    actionHandler={deleteHandler}
                />
            </div>
        ),
    },
];
