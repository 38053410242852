import Swal from 'sweetalert2';

export const DeletePopup = Swal.mixin({
    showCancelButton: true,
    confirmButtonColor: '#fca5a5',
    cancelButtonColor: '#808080',
    buttonsStyling: false,
    confirmButtonText: 'Delete',
    html: `<div style="display: flex; flex-direction: column; align-items: start; margin-bottom: 1rem; margin-top: 1rem;">
            <h2 style="float: left; font-size: 1.5rem; font-weight: 600;">Confirmation</h2>
            <p style="float: left; margin-top: 0.8rem;">Are you sure to delete this data?</p>
        </div>`,
    // focusConfirm: true,
    customClass: {
        confirmButton:
            'w-40 px-2 py-[0.75rem] mb-4 font-semibold foucs:outline-red-4000 text-red-600 bg-red-200 rounded-md mx-2',
        cancelButton:
            'w-40 px-2 py-[0.75rem] mb-4 font-semibold rounded-md bg-gray-50 mx-2',
        title: 'text-xl float-left',
    },
});
