import React from 'react';
import Modal from 'react-modal';
import customStyles from '../styles/customStyles';
import DataTable from 'react-data-table-component';
import CustomButton from '@components/buttons/CustomButton';
import { creditLimitHistoryColumns } from '@helpers/columns/customer/credit-limit-history.columns';
import { formatDateTime } from '@utils/general/fromat-date-time';
import { consignmentPaymentHistoryColumns } from '@helpers/columns/consignment/payment-history.columns';

interface ConsignmentPaymentReceiveHistoryModalProps {
    data: [] | undefined;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConsignmentPaymentReceiveHistoryModal: React.FC<
    ConsignmentPaymentReceiveHistoryModalProps
> = ({ data, isOpen, setIsOpen }) => {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="relative h-full">
                <div className="flex justify-end">
                    <svg
                        onClick={() => setIsOpen(false)}
                        xmlns="http://www.w3.org/2000/svg"
                        height="20px"
                        viewBox="0 -960 960 960"
                        width="20px"
                        fill="#3e3e3e"
                        className="cursor-pointer"
                    >
                        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                    </svg>
                </div>
                <div className="py-4 px-14">
                    <div className="flex items-center justify-between mb-6">
                        <h3 className="text-xl font-[600] uppercase text-primary-dark">
                            Payment History
                        </h3>
                        <div className="flex w-[33%] items-center justify-center ">
                            <div className="w-full">
                                <label className="relative block text-gray-400 focus-within:text-gray-600">
                                    <input
                                        // value={oldPassword}
                                        type="text"
                                        name="Search"
                                        id="search"
                                        placeholder="Search..."
                                        className="block w-full px-4 py-3 text-xs text-gray-500 placeholder-gray-400 bg-white rounded-md shadow-sm appearance-none border-[0.7px] border-primary-light form-input focus:outline-none"
                                    />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 -960 960 960"
                                        width="24px"
                                        fill="currentColor"
                                        className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                                    >
                                        <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                                    </svg>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="h-[410px] overflow-auto">
                        <DataTable
                            //@ts-ignore
                            columns={consignmentPaymentHistoryColumns()}
                            data={
                                data?.map((h: any) => ({
                                    ...h,
                                    // start_date: formatDateTime(
                                    //     new Date(h.start_date)
                                    // ),
                                })) || []
                            }
                            striped
                            responsive
                        />
                    </div>
                    <div className="absolute bottom-4 right-16">
                        <CustomButton
                            handleClick={() => setIsOpen(false)}
                            color="bg-primary-dark"
                            className="px-8 py-3"
                            title="Close"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConsignmentPaymentReceiveHistoryModal;
