export const USER_ROLE_ASSIGN = {
    BASE_PATH: '/user-management/user-role-assign/',
    GET_ALL: '/user-role-assign',
    GET_ONE: '/user-role-assign/edit/',
    GET_BY_ID: '/user-role-assign/user_id/',
    CREATE: '/user-role-assign/create',
    UPDATE: '/user-role-assign/update/',
    DELETE: '/user-role-assign/delete/',
    ADD_NEW_TITLE: 'Add New User Role Assign',
};
