import { TSecondarySaleOrder } from '@helpers/validation-schemas/primary-sale-order/sale-order.schema';
import { useGetSecondarySaleOrderQuery } from '@states/secondary-sale-order/secondary-sale-order.api';
import { TGetOneSuccessResponse } from '@type-defs/general/TGetOneSuccessResponse';

const useGetOneSecondarySalesorder = (id: string | number) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch,
    } = useGetSecondarySaleOrderQuery({
        secondary_sales_order_id: id!,
    });

    const secondarySalesOrderResponse =
        rawData as any as TGetOneSuccessResponse<TSecondarySaleOrder>;

    return {
        secondarySalesOrderData: secondarySalesOrderResponse?.data,
        secondarySalesOrderMessage: secondarySalesOrderResponse?.message,
        secondarySalesOrderSuccess: secondarySalesOrderResponse?.success,
        secondarySalesOrderError: error,
        secondarySalesOrderLoading: isLoading,
        secondarySalesOrderRefetch: refetch,
    };
};

export default useGetOneSecondarySalesorder;
