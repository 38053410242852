import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { CONSIGNMENT_RETURN } from '@utils/constants/consignment-sales-return /consignment-return.constants';

export const consignmentReturnApi = createApi({
    reducerPath: 'consignmentReturnApi',
    tagTypes: ['ConsignmentReturn'],
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseUrl(),
    }),
    endpoints: (builder) => ({
        getAllConsignmentReturns: builder.query<
            [],
            {
                page?: number;
                limit?: number;
                customer_first_name?: string;
               
            }
        >({
            query: ({
                page = 1,
                limit = 10,
            }) => {
                const queryParams = {
                    url: CONSIGNMENT_RETURN.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        
        getConsingmentReturn: builder.query<
            any,
            { consignment_return_id: string | number }
        >({
            query: ({ consignment_return_id }) =>
                `${CONSIGNMENT_RETURN.GET_ONE}${consignment_return_id}`,
        }),
        createConsignmentReturn: builder.mutation({
            query: (body) => ({
                url: CONSIGNMENT_RETURN.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateConsignmentReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${CONSIGNMENT_RETURN.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteConsignmentReturn: builder.mutation({
            query: ({ id, body }) => ({
                url: `${CONSIGNMENT_RETURN.DELETE}${id}`,
                method: 'DELETE',
                body,
            }),
        }),
       
    }),
});

export const {
    useGetAllConsignmentReturnsQuery,
    useGetConsingmentReturnQuery,
    useCreateConsignmentReturnMutation,
    useUpdateConsignmentReturnMutation,
    useDeleteConsignmentReturnMutation,
   
} = consignmentReturnApi;
