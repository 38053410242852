import { MenuIconProps } from '@type-defs/general/TMenuIconProps';

const PrimarySalesReturnIcon = (props: MenuIconProps) => {
    const { isRouted } = props;
    const color = isRouted ? '#ffffff' : '#6b7280';

    return (
        <svg
            width="24"
            height="23"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1833 20.0495V10.4661H17.95C18.0517 10.4661 18.1492 10.4258 18.2211 10.3539C18.2929 10.282 18.3333 10.1845 18.3333 10.0828V7.01615C18.3333 6.91448 18.2929 6.81698 18.2211 6.74509C18.1492 6.6732 18.0517 6.63281 17.95 6.63281H3.38333C3.28167 6.63281 3.18416 6.6732 3.11228 6.74509C3.04039 6.81698 3 6.91448 3 7.01615V10.0828C3 10.1845 3.04039 10.282 3.11228 10.3539C3.18416 10.4258 3.28167 10.4661 3.38333 10.4661H4.15V20.0495C4.15 20.1511 4.19039 20.2486 4.26228 20.3205C4.33416 20.3924 4.43167 20.4328 4.53333 20.4328H16.8C16.9017 20.4328 16.9992 20.3924 17.0711 20.3205C17.1429 20.2486 17.1833 20.1511 17.1833 20.0495ZM17.5667 9.69948H13.35V7.39948H17.5667V9.69948ZM12.5833 7.39948V9.69948H8.75V7.39948H12.5833ZM9.51667 10.4661H11.8167V13.6785L10.8392 13.1916C10.7857 13.1647 10.7266 13.1506 10.6667 13.1506C10.6068 13.1506 10.5477 13.1647 10.4942 13.1916L9.51667 13.6785V10.4661ZM3.76667 7.39948H7.98333V9.69948H3.76667V7.39948ZM16.4167 19.6661H4.91667V10.4661H8.75V14.2995C8.7502 14.3648 8.7671 14.429 8.79909 14.486C8.83109 14.543 8.87711 14.5908 8.93281 14.625C8.9885 14.6592 9.052 14.6786 9.11729 14.6813C9.18258 14.6841 9.24748 14.6701 9.30583 14.6406L10.6667 13.9621L12.0275 14.6406C12.0809 14.668 12.14 14.6825 12.2 14.6828C12.2718 14.683 12.3422 14.6631 12.4032 14.6253C12.4584 14.5908 12.5039 14.5427 12.5355 14.4857C12.567 14.4287 12.5835 14.3646 12.5833 14.2995V10.4661H16.4167V19.6661Z"
                fill={color}
                stroke={color}
                stroke-width="0.3"
            />
            <path
                d="M15.2668 18.1328H12.9668C12.8652 18.1328 12.7677 18.1732 12.6958 18.2451C12.6239 18.317 12.5835 18.4145 12.5835 18.5161C12.5835 18.6178 12.6239 18.7153 12.6958 18.7872C12.7677 18.8591 12.8652 18.8995 12.9668 18.8995H15.2668C15.3685 18.8995 15.466 18.8591 15.5379 18.7872C15.6098 18.7153 15.6502 18.6178 15.6502 18.5161C15.6502 18.4145 15.6098 18.317 15.5379 18.2451C15.466 18.1732 15.3685 18.1328 15.2668 18.1328Z"
                fill={color}
                stroke={color}
                stroke-width="0.3"
            />
            <path
                d="M12.5835 16.9829C12.5835 17.0846 12.6239 17.1821 12.6958 17.254C12.7677 17.3259 12.8652 17.3663 12.9668 17.3663H15.2668C15.3685 17.3663 15.466 17.3259 15.5379 17.254C15.6098 17.1821 15.6502 17.0846 15.6502 16.9829C15.6502 16.8813 15.6098 16.7838 15.5379 16.7119C15.466 16.64 15.3685 16.5996 15.2668 16.5996H12.9668C12.8652 16.5996 12.7677 16.64 12.6958 16.7119C12.6239 16.7838 12.5835 16.8813 12.5835 16.9829Z"
                fill={color}
                stroke={color}
                stroke-width="0.3"
            />
            <path
                d="M21.7827 2.80078H15.266C15.1644 2.80078 15.0669 2.84117 14.995 2.91306C14.9231 2.98495 14.8827 3.08245 14.8827 3.18411C14.8827 3.28578 14.9231 3.38328 14.995 3.45517C15.0669 3.52706 15.1644 3.56745 15.266 3.56745H21.3994V22.7341H11.0494V21.5841C11.0494 21.4824 11.009 21.3849 10.9371 21.3131C10.8652 21.2412 10.7677 21.2008 10.666 21.2008C10.5644 21.2008 10.4669 21.2412 10.395 21.3131C10.3231 21.3849 10.2827 21.4824 10.2827 21.5841V23.1174C10.2827 23.2191 10.3231 23.3166 10.395 23.3885C10.4669 23.4604 10.5644 23.5008 10.666 23.5008H21.7827C21.8844 23.5008 21.9819 23.4604 22.0538 23.3885C22.1257 23.3166 22.166 23.2191 22.166 23.1174V3.18411C22.166 3.08245 22.1257 2.98495 22.0538 2.91306C21.9819 2.84117 21.8844 2.80078 21.7827 2.80078Z"
                fill={color}
                stroke={color}
                stroke-width="0.3"
            />
            <path
                d="M10.4368 5.79C10.494 5.83265 10.562 5.85848 10.6331 5.86457C10.7042 5.87067 10.7756 5.85679 10.8393 5.8245C10.9025 5.79265 10.9557 5.74391 10.9929 5.68369C11.0301 5.62347 11.0499 5.55412 11.0501 5.48333V4.33333H13.7335C13.8351 4.33333 13.9326 4.29295 14.0045 4.22106C14.0764 4.14917 14.1168 4.05167 14.1168 3.95V2.41667C14.1168 2.315 14.0764 2.2175 14.0045 2.14561C13.9326 2.07372 13.8351 2.03333 13.7335 2.03333H11.0501V0.883334C11.0501 0.812144 11.0303 0.742361 10.9929 0.681803C10.9555 0.621246 10.9019 0.572307 10.8382 0.54047C10.7746 0.508633 10.7033 0.495156 10.6324 0.501549C10.5615 0.507942 10.4937 0.533953 10.4368 0.576667L7.37013 2.87667C7.32252 2.91237 7.28388 2.95867 7.25727 3.0119C7.23065 3.06513 7.2168 3.12382 7.2168 3.18333C7.2168 3.24284 7.23065 3.30154 7.25727 3.35477C7.28388 3.40799 7.32252 3.45429 7.37013 3.49L10.4368 5.79ZM10.2835 1.65V2.41667C10.2835 2.51833 10.3238 2.61584 10.3957 2.68772C10.4676 2.75961 10.5651 2.8 10.6668 2.8H13.3501V3.56667H10.6668C10.5651 3.56667 10.4676 3.60705 10.3957 3.67894C10.3238 3.75083 10.2835 3.84833 10.2835 3.95V4.71667L8.2403 3.18333L10.2835 1.65Z"
                fill={color}
                stroke={color}
                stroke-width="0.3"
            />
        </svg>
    );
};

export default PrimarySalesReturnIcon;
