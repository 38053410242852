import { z } from 'zod';

export const supplierSchema = z.object({
    supplier_name: z.string().min(1, {
        message: 'Supplier Name is required',
    }),
    supplier_phone1: z.string().min(1),
    supplier_email: z.string().nullable().optional(),
    supplier_address: z.string().nullable().optional(),
    township_name: z.any().nullable().optional(),
    township_id: z.union([z.string(), z.number().int()]).optional(),
    city_name: z.any().nullable().optional(),
    city_id: z.union([z.string(), z.number().int()]).optional(),
    state_id: z.union([z.string(), z.number().int()]).optional(),
    state_name: z.any().nullable().optional(),
    contact_first_name: z.string().nullable().optional(),
    contact_last_name: z.string().nullable().optional(),
    contact_email: z.string().nullable().optional(),
    contact_phoneno: z.string(),
    remark: z.string(),
    description: z.string(),
});

export type ZSupplier = z.infer<typeof supplierSchema>;

export interface TSupplier extends ZSupplier {
    supplier_id: string | number;
}
