export const DISTRIBUTION_REGION = {
    BASE_PATH: '/distribution-region/distribution-region-management/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Distribution Region',
    LIST_PAGE_TITLE: 'Distribution Region List',
    EDIT_PAGE_TITLE: 'Edit Distribution Region',
    GET_ALL: '/distribution-region-management',
    GET_ONE: '/distribution-region-management/edit/',
    GET_ALL_REGIONS: '/distribution-region-management/region',
    CREATE: '/distribution-region-management/create',
    UPDATE: '/distribution-region-management/update/',
    DELETE: '/distribution-region-management/delete/',
    ADD_NEW_TITLE: 'Add New Distribution Region',
};
