export const SUPPLIER = {
    BASE_PATH: '/supplier/',
    GET_ALL: '/supplier',
    CREATE: '/supplier/create',
    UPDATE: '/supplier/update/',
    DELETE: '/supplier/delete/',
    ADD_NEW_TITLE: 'Add New Supplier',
    LIST_TITLE: 'Supplier List',
    EDIT_TITLE: 'Edit Supplier',
};
