import PageWrapper from '@components/layouts/PageWrapper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useErrorHandling } from '@hooks/useErrorHandling';
import { castTypeArr } from '@utils/general/cast-type';
import { TFailResponse } from '@type-defs/general/TFailResponse';
import { Toast } from '@helpers/popups/Toast';
import { LoadingSpinner } from '@components/layouts/LoadingSpinner';
import {
    useApprovePrimaryOrderMutation,
    useUpdatePrimarySaleOrderMutation,
} from '@states/primary-sale-order/primary-sale-order.api';
import BackButton from '@components/buttons/BackButton';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { saleOrderSchema } from '@helpers/validation-schemas/primary-sale-order/sale-order.schema';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CustomDateField from '@components/form/CustomDateField';
import CustomSearchModalChooseField from '@components/form/CustomSearchModalChooseField';
import useGetAllCustomers from '@hooks/customer/useGetAllCustomers';
import CustomSelectField from '@components/form/CustomSelectField';
import CustomTextAreaField from '@components/form/CustomTextAreaField';
import { useLazyGetProductByBarcodeQuery } from '@states/product/product.api';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { thousandSeperator } from '@utils/general/digit-separators';
import CustomInputField from '@components/form/CustomInputField';
import CustomButton from '@components/buttons/CustomButton';
import { productDetailColumns } from '@helpers/columns/primary-sale-order/product-detail.columns';
import useGetOnePrimarySaleorder from '@hooks/primary-sales-order/useGetOnePrimarySaleOrder';
import {
    addFormValues,
    addProductDetail,
    removeProductDetail,
    resetFormValues,
    updateProductDetail,
} from '@states/common/common.slice';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';
import { TProduct } from '@type-defs/product/TProduct';
import { PRIMARY_SALE_ORDER } from '@utils/constants/primary-sale-order.constants';
import { calculatePercentage } from '@utils/general/cal-percent';
import useGetOneSecondarySalesorder from '@hooks/secondary-sales-order/useGetOneSecondarySaleOrder';
import { useLazyGetCustomerQuery } from '@states/customer/customer.api';
import { useApproveSecondaryOrderMutation } from '@states/secondary-sale-order/secondary-sale-order.api';
import { useGetAllPaymentTermsQuery } from '@states/common/common.api';
import useGetAllPaymentTypes from '@hooks/common/useGetAllPaymentTypes';

type FormFields = z.infer<typeof saleOrderSchema>;

const OrderApprovalEditPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productBarcodeRef = useRef(null);
    const [businessUnits, setBusinessUnits] = useState<any>([]);

    const { formValues } = useSelector((state: RootState) => state.common);

    const {
        secondarySalesOrderData,
        secondarySalesOrderRefetch,
        secondarySalesOrderLoading,
    } = useGetOneSecondarySalesorder(id!);

    const defaultValues = {
        secondary_sales_order_id:
            secondarySalesOrderData?.secondary_sales_order_id,
        sales_date: secondarySalesOrderData?.sales_date,
        customer_id: secondarySalesOrderData?.customer_id,
        business_unit_id: secondarySalesOrderData?.business_unit_id,
        payment_type_id: secondarySalesOrderData?.payment_type_id,
        payment_terms_id: secondarySalesOrderData?.payment_terms_id,
        customer_name:
            secondarySalesOrderData?.customer_first_name +
            ' ' +
            secondarySalesOrderData?.customer_last_name,
        product_detail: secondarySalesOrderData?.product_detail,
        sub_total: secondarySalesOrderData?.sub_total,
        grand_total_amount: secondarySalesOrderData?.grand_total_amount,
        discount_amount: secondarySalesOrderData?.discount_amount,
        tax_amount: secondarySalesOrderData?.tax_amount,
        other_charges: secondarySalesOrderData?.other_charges,
        discount_type: secondarySalesOrderData?.discount_type,
        tax_type: secondarySalesOrderData?.tax_type,
        credit_limit: secondarySalesOrderData?.credit_limit,
        credit_balance: secondarySalesOrderData?.credit_balance,
        current_amount: secondarySalesOrderData?.grand_total_amount,
        discount: secondarySalesOrderData?.discount,
        tax: secondarySalesOrderData?.tax,
    };

    const {
        register,
        handleSubmit,
        setError,
        watch,
        setValue,
        getValues,
        control,
        formState: { errors, isSubmitting }, //TODO: need to handle wrong field name errors, cause they are uncaught
        reset,
    } = useForm<FormFields>({
        defaultValues,
        resolver: zodResolver(saleOrderSchema),
    });

    const { append, remove, update } = useFieldArray({
        control,
        name: 'product_detail',
    });

    const [
        approvePrimaryOrder,
        { isLoading, error: updateError, isSuccess, data },
    ] = useApproveSecondaryOrderMutation();
    const [getOneCustomer, { data: getOneCustomerData }] =
        useLazyGetCustomerQuery();
    const { customerData } = useGetAllCustomers({ limit: 1000 });
    const { data: paymentTermData } = useGetAllPaymentTermsQuery();
    const { paymentTypeData } = useGetAllPaymentTypes();

    const [
        getProductByBarcode,
        { data: productData, isLoading: productLoading },
    ] = useLazyGetProductByBarcodeQuery();

    useErrorHandling(...castTypeArr<TFailResponse>([updateError]));

    useEffect(() => {
        if (formValues?.customer_id) {
            getOneCustomer({
                id: formValues?.customer_id,
            });
        }
    }, [formValues?.customer_id]);

    useEffect(() => {
        if (getOneCustomerData?.data) {
            setBusinessUnits(getOneCustomerData?.data?.business_units || []);
        }
    }, [getOneCustomerData]);

    useEffect(() => {
        if (id) {
            secondarySalesOrderRefetch();
        }
    }, [id, secondarySalesOrderRefetch]);

    useEffect(() => {
        if (isSuccess && data) {
            Toast.fire({
                title: data.message,
                icon: 'success',
            });

            navigate(PRIMARY_SALE_ORDER.BASE_PATH, {
                state: {
                    reload: true,
                },
            });
        }
    }, [isSuccess, data, navigate, Toast]);

    useEffect(() => {
        const pd = defaultValues?.product_detail?.map((dtl: any) => ({
            product_id: dtl.product_id,
            product_code: dtl.product_code,
            product_name: dtl.product_name,
            unit_name: dtl.unit_name,
            unit_id: dtl.unit_id,
            sales_price: dtl.sales_price,
            qty: dtl.qty,
            amount: dtl.amount,
            discount_type: dtl.discount_type,
            discount_amount: dtl.discount_amount,
            tax_type: dtl.tax_type,
            tax_amount: dtl.tax_amount,
            total_amount: dtl.total_amount,
            discount: dtl.discount,
            tax: dtl.tax,
            warehouse_id: dtl.warehouse_id,
            warehouse_name: dtl.warehouse_name,
        }));

        dispatch(addFormValues({ ...defaultValues, product_detail: pd }));

        reset({ ...defaultValues, product_detail: pd });
    }, [secondarySalesOrderData, reset, dispatch]);

    useEffect(() => {
        const productResponse =
            productData as any as TSuccessResponse<TProduct>;

        if (productResponse && productResponse.data.length > 0) {
            const product = productResponse.data[0];

            const existingProductIds = formValues?.product_detail?.map(
                (pd: any) => pd.product_id
            );

            if (!existingProductIds?.includes(product.product_id)) {
                const productDetail = {
                    product_id: product.product_id,
                    product_code: product.product_code,
                    product_name: product.product_name,
                    unit_id: product.unit_id,
                    unit_name: product.unit_name,
                    sales_price: product.secondary_sales_price,
                    qty: 5,
                    amount: 5 * product.secondary_sales_price,
                    discount: 0,
                    discount_amount: 0,
                    discount_type: 'AMT',
                    tax: 0,
                    tax_type: 'AMT',
                    tax_amount: 0,
                    total_amount: 5 * product.secondary_sales_price,
                };

                append(productDetail);
                dispatch(addProductDetail(product));
            }

            //@ts-ignore
            productBarcodeRef.current.value = '';
        }
    }, [productData]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const { product_detail, ...theRest } = value;

            const targetIndex = Number(name?.split('.')?.[1]);
            const targetProductDetail: any = product_detail![targetIndex!];

            if (targetProductDetail && product_detail) {
                if (targetProductDetail) {
                    // console.log(targetProductDetail, 'kkkkk');
                    const updatedProductAmount =
                        targetProductDetail.sales_price *
                        targetProductDetail.qty;

                    const updatedProductDiscount =
                        targetProductDetail.discount_type === '%'
                            ? updatedProductAmount *
                              ((targetProductDetail.discount || 0) / 100)
                            : targetProductDetail.discount || 0;

                    const updatedProductTax =
                        targetProductDetail.tax_type === '%'
                            ? updatedProductAmount *
                              ((targetProductDetail.tax || 0) / 100)
                            : targetProductDetail.tax || 0;

                    const updatedTotalAmount =
                        updatedProductAmount +
                        updatedProductTax -
                        updatedProductDiscount;

                    update(targetIndex, {
                        ...targetProductDetail,
                        discount_amount: updatedProductDiscount,
                        tax_amount: updatedProductTax,
                        // amount
                        amount: updatedProductAmount,
                        // total amount
                        total_amount: updatedTotalAmount,
                    });

                    // TODO: check whether this is actaully necessary
                    updateProductDetail({
                        index: targetIndex,
                        product_detail: {
                            ...targetProductDetail,
                            discount_amount: updatedProductDiscount,
                            tax_amount: updatedProductTax,
                            amount: updatedProductAmount,
                            total_amount: updatedTotalAmount,
                        },
                    });
                }
            }

            // Calculate sub_total
            const subTotal =
                product_detail?.reduce((acc, cur) => {
                    if (cur) {
                        return acc + (cur.total_amount || 0);
                    }
                    return acc;
                }, 0) || 0;

            // Calculate discount_amount based on sub_total
            const discountAmount =
                theRest.discount_type === '%'
                    ? subTotal * (theRest.discount! / 100)
                    : theRest.discount || 0;

            // Calculate discount_amount based on sub_total
            const taxAmount =
                theRest.tax_type === '%'
                    ? subTotal * (theRest.tax! / 100)
                    : theRest.tax || 0;

            const otherCharges = theRest.other_charges || 0;

            //@ts-ignore
            dispatch(
                addFormValues({
                    ...theRest,
                    // @ts-ignore
                    product_detail: product_detail?.map((pd) => ({ ...pd })),
                    sub_total: subTotal,
                    discount_amount: discountAmount,
                    discount: theRest.discount || 0,
                    tax_amount: taxAmount,
                    tax: theRest.tax || 0,
                    other_charges: otherCharges,
                    grand_total_amount:
                        subTotal + otherCharges + taxAmount - discountAmount,
                    credit_balance: theRest.credit_balance || 0,
                    //@ts-ignore
                    // theRest.credit_limit -
                    // (subTotal + otherCharges + taxAmount - discountAmount),
                    current_amount:
                        subTotal + otherCharges + taxAmount - discountAmount,
                })
            );
        });

        return () => {
            subscription.unsubscribe();
            dispatch(resetFormValues());
        };
    }, [watch, dispatch]);

    const handleRemove = (index: number) => {
        remove(index);
        dispatch(removeProductDetail(index));
    };

    const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const barcode = e.target.value;

        getProductByBarcode({ barcode });
    };

    const onSubmit: SubmitHandler<FormFields> = async (data) => {
        try {
            // async stuff here
            // console.log(data, 'data');
            await asyncDispatcher(data);
        } catch (error: any) {
            setError('root', {
                message: error.message,
            });
        }
    };

    const asyncDispatcher = useCallback(
        async (reqBody: any) => {
            try {
                const { product_detail, discount, tax, ...rest } = formValues;

                const trimmedValues = {
                    status: formValues.status,
                    credit_balance: formValues.credit_balance,
                    current_amount: formValues.current_amount,
                    customer_id: formValues.customer_id,
                    reason: formValues.reason,
                };

                await approvePrimaryOrder({
                    id: id!,
                    body: trimmedValues,
                });
            } catch (error) {
                console.log('Error', error);
            }
        },
        [approvePrimaryOrder, isSuccess, Toast, navigate, formValues]
    );

    // console.log(formValues, 'formValues');

    // console.log(secondarySalesOrderData, 'secondarySalesOrderData');

    return (
        <PageWrapper>
            {(isLoading || productLoading || secondarySalesOrderLoading) && (
                <LoadingSpinner />
            )}
            <BackButton />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-center justify-between mb-4">
                    <h3 className="ml-2 text-2xl font-[600] text-primary-dark uppercase">
                        Edit Sale Order Approval
                    </h3>
                </div>
                <div className="grid grid-cols-6 gap-x-4 gap-y-8">
                    <CustomDateField
                        disabled
                        errors={errors}
                        name="sales_date"
                        label="Sale Date"
                        register={register}
                        key="sales_date"
                        //@ts-ignore
                        control={control}
                        value={secondarySalesOrderData?.sales_date}
                    />
                    <CustomSearchModalChooseField
                        disabled
                        key={'customer_id'}
                        errors={errors}
                        colSpan={2}
                        name={'customer_id'}
                        label="Customer Name"
                        title={'Customer List'}
                        columns={[
                            {
                                name: 'customer_name',
                                columnName: 'Customer Name',
                            },
                            {
                                name: 'phone_number',
                                columnName: 'Phone Number',
                            },
                        ]}
                        register={register}
                        // borderColor={field.borderColor}
                        placeHolder="Choose customer name"
                        setValue={setValue}
                        data={customerData?.map((customer) => ({
                            customer_id: customer.customer_id,
                            customer_name:
                                customer.customer_first_name +
                                ' ' +
                                (customer.customer_last_name || '...'),
                            phone_number: customer.customer_phone1,
                        }))}
                        columnName={'Customer Name'}
                        idName={'customer_id'}
                        // value={field.value}
                        nameName={'customer_name'}
                        value={
                            secondarySalesOrderData?.customer_first_name +
                            ' ' +
                            (secondarySalesOrderData?.customer_last_name ||
                                '...')
                        }
                    />
                    <CustomSelectField
                        disabled
                        errors={errors}
                        name="business_unit_id"
                        label="Business Unit"
                        placeHolder="Select business unit"
                        //@ts-ignore
                        control={control}
                        options={businessUnits?.map((bu: any) => ({
                            label: bu.business_unit_name,
                            value: bu.business_unit_id,
                        }))}
                    />
                    <CustomSelectField
                        disabled
                        errors={errors}
                        name="payment_type_id"
                        label="Payment Type"
                        placeHolder="Select payment type"
                        //@ts-ignore
                        control={control}
                        options={paymentTypeData?.map((item: any) => ({
                            label: item.payment_type_name,
                            value: item.payment_type_id,
                        }))}
                        value={secondarySalesOrderData?.payment_type_id}
                    />
                    <CustomSelectField
                        disabled
                        errors={errors}
                        name="payment_terms_id"
                        label="Payment Term"
                        placeHolder="Select payment term"
                        //@ts-ignore
                        control={control}
                        options={paymentTermData?.map((item: any) => ({
                            label: item.payment_terms,
                            value: item.payment_terms_id,
                        }))}
                        value={secondarySalesOrderData?.payment_terms_id}
                    />
                    <div></div>
                    <CustomTextAreaField
                        disabled
                        key={'remark'}
                        colSpan={3}
                        errors={errors}
                        name="remark"
                        register={register}
                    />
                    <CustomTextAreaField
                        disabled
                        key={'description'}
                        errors={errors}
                        colSpan={3}
                        name="description"
                        register={register}
                    />
                    <div className="flex justify-between col-span-6">
                        <h3 className="ml-2 font-[600] text-primary-dark">
                            <span className=" text-[1.1rem]">
                                Add Sale Order Detail
                            </span>
                        </h3>
                    </div>
                    <div className="flex flex-col justify-between col-span-6">
                        <h3 className="ml-2 font-[500] uppercase text-primary-dark">
                            <span className=" text-[1.3rem]">
                                Product Detail
                            </span>
                        </h3>
                        {/* <div className={`flex flex-col p-2 mt-5 w-[25%]`}>
                            <label
                                className="mb-2 text-gray-700"
                                style={{ textTransform: 'capitalize' }}
                                htmlFor="name"
                            >
                                Product Barcode
                            </label>
                            <input
                                disabled
                                ref={productBarcodeRef}
                                onChange={handleBarcodeChange}
                                className={`px-2 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                            />
                        </div> */}
                    </div>
                    <div className="-mt-4 col-span-full">
                        <DataTable
                            className="pb-4"
                            responsive
                            striped
                            //@ts-ignore
                            columns={productDetailColumns(
                                errors,
                                register,
                                setValue,
                                handleRemove,
                                control,
                                true
                            )}
                            //@ts-ignore
                            data={formValues?.product_detail?.map((f, i) => ({
                                ...f,
                                index: i,
                            }))}
                        />

                        {formValues?.product_detail?.length > 0 && (
                            <div className="bg-zinc-100 opacity-[0.8] rounded w-[48%] 2xl:w-[36%] mt-8 float-right p-6">
                                <div className="flex items-center mb-4">
                                    <h5 className="text-[1.05rem] basis-3/12">
                                        Sub Total
                                    </h5>
                                    <span className="s basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12"></div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.sub_total || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Discount
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            disabled
                                            classNames="-mr-3 w-[130px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'discount'}
                                            placeHolder=" "
                                            register={register}
                                            defaultValue={
                                                secondarySalesOrderData?.discount_type !==
                                                'AMT'
                                                    ? calculatePercentage(
                                                          secondarySalesOrderData?.sub_total ||
                                                              0,
                                                          secondarySalesOrderData?.discount_amount ||
                                                              0
                                                      )
                                                    : secondarySalesOrderData?.discount_amount
                                            }
                                        />
                                        <select
                                            disabled
                                            value={formValues?.discount_type}
                                            {...register('discount_type')}
                                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                        >
                                            <option selected value="AMT">
                                                AMT
                                            </option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        -
                                        {thousandSeperator(
                                            formValues.discount_amount || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Tax
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            disabled
                                            defaultValue={
                                                secondarySalesOrderData.tax_type !==
                                                'AMT'
                                                    ? calculatePercentage(
                                                          secondarySalesOrderData?.sub_total ||
                                                              0,
                                                          secondarySalesOrderData?.tax_amount ||
                                                              0
                                                      )
                                                    : secondarySalesOrderData?.tax_amount
                                            }
                                            classNames="-mr-3 w-[130px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'tax'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                        <select
                                            disabled
                                            value={formValues?.tax_type}
                                            {...register('tax_type')}
                                            className={`px-2 ml-2 h-12 py-3 border-[0.7px] focus:ring-1 border-primary-light ring-primary focus:outline-none rounded`}
                                        >
                                            <option selected value="AMT">
                                                AMT
                                            </option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.tax_amount || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-4">
                                    <h5 className=" basis-3/12 text-[1.05rem]">
                                        Other Charges
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12">
                                        <CustomInputField
                                            disabled
                                            defaultValue={0}
                                            classNames="-mr-3 w-[200px]"
                                            noLabel
                                            inputType="number"
                                            errors={errors}
                                            name={'other_charges'}
                                            placeHolder=" "
                                            register={register}
                                        />
                                    </div>
                                    <h5 className="text-right basis-2/12 text-[1.05rem]">
                                        {thousandSeperator(
                                            formValues.other_charges || 0
                                        )}
                                    </h5>
                                </div>
                                <div className="flex items-center mb-2">
                                    <h5 className="text-xl font-semibold basis-3/12">
                                        Grand Total
                                    </h5>
                                    <span className=" basis-1/12">:</span>
                                    <div className="flex items-center basis-6/12"></div>
                                    <h5 className="text-xl font-semibold text-right basis-2/12 text-primary-dark">
                                        {thousandSeperator(
                                            formValues.grand_total_amount || 0
                                        )}
                                    </h5>
                                </div>
                            </div>
                        )}
                    </div>
                    <CustomInputField
                        disabled
                        errors={errors}
                        name="credit_limit"
                        label="Credit Limit"
                        register={register}
                        //@ts-ignore
                        defaultValue={thousandSeperator(
                            formValues?.credit_limit || 0
                        )}
                    />
                    <CustomInputField
                        disabled
                        errors={errors}
                        placeHolder={thousandSeperator(
                            formValues?.credit_balance || 0
                        )}
                        name="credit_balance"
                        label="Credit Balance"
                        register={register}
                        defaultValue={formValues?.credit_balance || 0}
                    />
                    <CustomInputField
                        disabled
                        errors={errors}
                        name="current_amount"
                        label="Current Amount"
                        register={register}
                        defaultValue={thousandSeperator(
                            formValues?.current_amount || 0
                        )}
                    />
                    <CustomSelectField
                        //@ts-ignore
                        control={control}
                        errors={errors}
                        name="status"
                        label="Status"
                        options={[
                            {
                                label: 'Approve & Open',
                                value: 3,
                            },
                            {
                                label: 'Reject',
                                value: 4,
                            },
                        ]}
                    />
                    <CustomInputField
                        register={register}
                        name="reason"
                        errors={errors}
                        colSpan={4}
                    />
                </div>
                <div className="ml-2 mt-14">
                    <CustomButton
                        isLarge
                        title="Cancel"
                        color="white"
                        textColor="gray.800"
                        className="mr-10"
                        handleClick={() => navigate(-1)}
                    />
                    <CustomButton
                        handleClick={handleSubmit(onSubmit)}
                        isLarge
                        title={isSubmitting ? 'Loading...' : 'Submit'}
                        disabled={isSubmitting}
                        type="submit"
                    />
                </div>
                {errors.root && (
                    <div className="mt-6 text-red-500">
                        {errors.root.message}
                    </div>
                )}
            </form>
        </PageWrapper>
    );
};

export default OrderApprovalEditPage;
