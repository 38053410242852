type What = {
    name: string;
    columnName: string;
}[];

export const searchModalColumns = (idName: string, arr: What) => {
    let columns = arr.map((item: any) => {
        return {
            name: item.columnName,
            selector: (row: any) => row[item.name],
            sortable: true,
        };
    });

    return columns;
};
