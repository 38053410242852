import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '@utils/general/getBaseUrl';
import { DIRECT_PAYMENT_RECEIVE } from '@utils/constants/direct-payment-receive.constants';
import { baseQueryWithReauth } from '@states/auth/auth.api';

export const directPaymentReceiveApi = createApi({
    reducerPath: 'directPaymentReceiveApi',
    tagTypes: ['DirectPaymentReceive'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllDirectPaymentReceives: builder.query<
            [],
            {
                page?: number;
                limit?: number;
            }
        >({
            query: ({ page = 1, limit = 10 }) => {
                const queryParams = {
                    url: DIRECT_PAYMENT_RECEIVE.GET_ALL,
                    params: { page, limit },
                };

                return queryParams;
            },
        }),
        createDirectPaymentReceive: builder.mutation({
            query: (body) => ({
                url: DIRECT_PAYMENT_RECEIVE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        deleteDirectPaymentReceive: builder.mutation({
            query: (id) => ({
                url: `${DIRECT_PAYMENT_RECEIVE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllDirectPaymentReceivesQuery,
    useCreateDirectPaymentReceiveMutation,
    useDeleteDirectPaymentReceiveMutation,
} = directPaymentReceiveApi;
