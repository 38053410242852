export const TRIP_PAYMENT_RECEIVE = {
    BASE_PATH: '/trip/payment-receive/',
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    LIST_PAGE_TITLE: 'Trip Payment Receive List',
    EDIT_PAGE_TITLE: 'Edit Trip Payment Receive',
    GET_ALL: 'trip/payment-receive',
    GET_ONE: 'trip/payment-receive/edit/',
    CREATE: 'trip/payment-receive/create',
    UPDATE: 'trip/payment-receive/update/',
    DELETE: 'trip/payment-receive/delete/',
};
