import { useGetAllCustomersQuery } from '@states/customer/customer.api';
import { useGetAllOfficeUsersQuery } from '@states/user-management/office-user.api';
import { TCustomer } from '@type-defs/customer/TCustomer';
import { TSuccessResponse } from '@type-defs/general/TSuccessResponse';
import { TUser } from '@type-defs/general/TUser';

const useGetAllCustomers = (args: {
    page?: number;
    limit?: number;
    customer_first_name?: string;
    customer_type_id?: number;
    customer_category_id?: number;
}) => {
    const {
        data: rawData,
        error,
        isLoading,
        refetch,
        isFetching,
    } = useGetAllCustomersQuery({ ...args });

    const customerResponse = rawData as any as TSuccessResponse<TCustomer>;

    return {
        customerData: customerResponse?.data,
        customerMessage: customerResponse?.message,
        customerSuccess: customerResponse?.success,
        customerPaginator: customerResponse?.paginator,
        customerError: error,
        customerLoading: isLoading,
        customerRefetch: refetch,
        customerFetching: isFetching,
    };
};

export default useGetAllCustomers;
