export const CONSIGNMENT_INVOICE = {
    BASE_PATH: '/consignment/invoice/',
    MAKE_PAYMENT_RECEIVE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/make-payment-receive';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New Consignment Invoice',
    LIST_PAGE_TITLE: 'Consignment Order List',
    EDIT_PAGE_TITLE: 'Edit Consignment Order',
    GET_ALL: '/consignment/invoice',
    GET_ONE: '/consignment/invoice/edit/',
    CREATE: '/consignment/invoice/create',
    UPDATE: '/consignment/invoice/update/',
    DELETE: '/consignment/invoice/delete/',
    ADD_NEW_TITLE: 'Add New Consignment Invoice',
};
