import { MenuIconProps } from '@type-defs/general/TMenuIconProps';

const TripIcon = (props: MenuIconProps) => {
    const { isRouted } = props;
    const color = isRouted ? '#ffffff' : '#6b7280';

    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1952_145889)">
                <path
                    d="M18.5043 0.5C15.7747 0.5 13.5542 2.72051 13.5542 5.45008C13.5542 8.07046 18.0234 14.4177 18.2136 14.6864C18.2803 14.781 18.3888 14.837 18.5043 14.837C18.6197 14.837 18.7282 14.781 18.795 14.6864C18.9852 14.4177 23.4544 8.07046 23.4544 5.45008C23.4544 2.72051 21.2338 0.5 18.5043 0.5ZM18.5043 13.8547C17.5036 12.388 14.2663 7.4856 14.2663 5.45008C14.2663 3.11343 16.1676 1.21212 18.5043 1.21212C20.8409 1.21212 22.7422 3.11343 22.7422 5.45008C22.7422 7.4856 19.505 12.388 18.5043 13.8547Z"
                    fill={color}
                    stroke={color}
                    stroke-width="0.4"
                />
                <path
                    d="M18.5046 2.38867C16.9423 2.38867 15.6714 3.65992 15.6714 5.2222C15.6714 6.78449 16.9423 8.05539 18.5046 8.05539C20.0669 8.05539 21.3378 6.78449 21.3378 5.2222C21.3378 3.65992 20.0669 2.38867 18.5046 2.38867ZM18.5046 7.34327C17.3349 7.34327 16.3835 6.39192 16.3835 5.2222C16.3835 4.05249 17.3349 3.10079 18.5046 3.10079C19.6743 3.10079 20.6256 4.05249 20.6256 5.2222C20.6256 6.39192 19.6743 7.34327 18.5046 7.34327Z"
                    fill={color}
                    stroke={color}
                    stroke-width="0.4"
                />
                <path
                    d="M2.45056 13.6636C2.49068 15.4931 3.98829 16.9696 5.82727 16.9696C7.69102 16.9696 9.20741 15.4532 9.20741 13.5891C9.20741 11.7254 7.69102 10.209 5.82727 10.209C3.96316 10.209 2.44678 11.7254 2.44678 13.5891C2.44678 13.6069 2.44917 13.6242 2.44947 13.642C2.44869 13.6491 2.45091 13.6564 2.45056 13.6636ZM5.82727 16.2575C4.46996 16.2575 3.34832 15.2378 3.18237 13.9248C3.80356 13.6901 5.2977 13.0197 5.91454 11.8092C5.95696 11.8829 6.00321 11.9569 6.05363 12.0307C6.45637 12.6191 7.19388 13.2695 8.46138 13.1855C8.48155 13.3175 8.49529 13.4516 8.49529 13.5891C8.49529 15.0603 7.29845 16.2575 5.82727 16.2575ZM8.24902 12.4763C7.03575 12.4666 6.51348 11.5463 6.30572 10.9665C7.1711 11.124 7.89057 11.6996 8.24902 12.4763ZM5.45082 10.9508C5.26501 12.0995 3.94091 12.8262 3.19863 13.1472C3.38974 12.0084 4.30288 11.1141 5.45082 10.9508Z"
                    fill={color}
                    stroke={color}
                    stroke-width="0.4"
                />
                <path
                    d="M23.0987 15.7277C23.2955 15.7277 23.4548 15.5685 23.4548 15.3717C23.4548 15.1749 23.2955 15.0156 23.0987 15.0156H11.2976C11.1801 15.0156 11.0699 15.0737 11.0035 15.1711L9.29104 17.6801C8.89739 17.5436 8.47339 17.4653 8.0291 17.4653H7.85802C7.84768 17.4653 7.83898 17.4703 7.82881 17.4712C7.79817 17.4739 7.76909 17.4786 7.74019 17.489C7.72215 17.4954 7.70642 17.5041 7.68986 17.5131C7.66773 17.5253 7.64713 17.538 7.62775 17.5549C7.6094 17.5708 7.59497 17.5888 7.5802 17.6081C7.57163 17.6193 7.55972 17.6266 7.55238 17.6391L5.82667 20.5739L4.1013 17.6391C4.09396 17.6267 4.08209 17.6194 4.07353 17.6081C4.05871 17.5889 4.04423 17.5708 4.02589 17.5549C4.00655 17.538 3.98599 17.5253 3.96387 17.5132C3.94735 17.5041 3.93153 17.4954 3.91349 17.489C3.88459 17.4786 3.85547 17.4739 3.82487 17.4712C3.8147 17.4703 3.80601 17.4653 3.79566 17.4653H3.62459C1.62592 17.4653 0 18.9765 0 20.8336V23.6428C0 23.8396 0.159254 23.9989 0.356061 23.9989H2.48556C2.4886 23.9989 2.49112 24.0006 2.49416 24.0006C2.49721 24.0006 2.49973 23.9989 2.50277 23.9989H9.15091C9.15396 23.9989 9.15648 24.0006 9.15952 24.0006C9.16256 24.0006 9.16508 23.9989 9.16813 23.9989H11.2976C11.4944 23.9989 11.6537 23.8396 11.6537 23.6428V20.8336C11.6537 19.6315 10.9703 18.5774 9.94779 17.9812L11.4857 15.7277H23.0987ZM10.9416 20.8336V23.2867H9.51558V21.4025C9.51558 21.2057 9.35633 21.0464 9.15952 21.0464C8.96271 21.0464 8.80346 21.2057 8.80346 21.4025V23.2867H2.85022V21.4025C2.85022 21.2057 2.69097 21.0464 2.49416 21.0464C2.29736 21.0464 2.1381 21.2057 2.1381 21.4025V23.2867H0.712121V20.8336C0.712121 19.3789 2.00123 18.1949 3.59238 18.1789L5.51963 21.4567C5.58361 21.5655 5.70045 21.6323 5.82667 21.6323C5.95289 21.6323 6.06972 21.5655 6.1337 21.4567L8.06126 18.1789C9.65245 18.1949 10.9416 19.3789 10.9416 20.8336Z"
                    fill={color}
                    stroke={color}
                    stroke-width="0.4"
                />
            </g>
            <defs>
                <clipPath id="clip0_1952_145889">
                    <rect width="24.2168" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TripIcon;
