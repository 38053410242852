
import { z } from 'zod';

export const invoiceConsignmentSchema = z.object({
    sales_date: z.union([z.string(), z.date()], {
        message: 'sales date must be a valid date',
    }),
    invoice_date: z.union([z.string(), z.date()], {
        message: 'Invoice date must be a valid date',
    }),
    due_date: z.union([z.string(), z.date()], {
        message: 'Due date must be a valid date',
    }),
    manual_invoice_id: z.string().optional(),

    customer_id: z.union([z.string(), z.number()]),
    consignment_contract_id: z.union([z.string(), z.number()]).optional(),
    payment_type_id: z.union([z.string(), z.number()]),
    payment_term_id: z.union([z.string(), z.number()]).optional(),
    remark: z.string().optional(),
    description: z.string().optional(),
    product_detail: z.array(
        z.object({
            product_id: z.union([z.string(), z.number()]),
            unit_id: z.union([z.string(), z.number()]),
            sales_price: z.number(),
            qty: z.number(),
            total_amount: z.number(),
            product_code: z.string().optional(),
            product_name: z.string().optional(),
            unit_name: z.string().optional(),
            amount: z.number().optional().default(0),
            discount: z.number().optional().default(0),
            discount_amount: z.number().default(0),
            discount_type: z.string().optional().default('AMT'),
            tax: z.number().optional(),
            tax_amount: z.number().default(0),
            tax_type: z.string().optional().default('AMT'),
        })
    ),
    tax: z.number().optional().default(0),
    tax_type: z.string().optional(),
    tax_amount: z.number().default(0),
    discount: z.number().optional().default(0),
    discount_type: z.string().optional(),
    discount_amount: z.number().default(0),
    other_charges: z.number().optional().default(0),
    grand_total_amount: z.number().default(0),
    sub_total: z.number().default(0),
    current_amount: z.number().optional().nullable(),
    credit_balance: z.number().optional().nullable(),
});
export type ZInvoiceConsignment = z.infer<typeof invoiceConsignmentSchema>;
