import { useState } from 'react';

const usePagination = (initialPage = 1, initialLimit = 10) => {
    const [page, setPage] = useState(initialPage);
    const [limit, setLimit] = useState(initialLimit);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = async (limit: number, page: number) => {
        setLimit(limit);
        setPage(page);
    };

    return {
        page,
        limit,
        handlePageChange,
        handlePerRowsChange,
    };
};

export default usePagination;
