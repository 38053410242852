import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import DataTable from 'react-data-table-component';
import { generateEmptyArrays } from '../../utils/general/generateEmptyArrays';

type TableSkeletonArgs = {
    col?: number;
    row?: number;
    actionRight?: boolean;
    columnNames?: string[];
};

const TableSkeleton = ({
    col = 6,
    row = 5,
    actionRight = false,
    columnNames = [],
}: TableSkeletonArgs) => {
    const columns = [];

    for (let i = 0; i < col; i++) {
        const isLastItem = i === col - 1;

        columns.push({
            name: columnNames.length > 0 ? columnNames[i] : '',
            selector: () => (
                <div className="w-24 h-6">
                    <Skeleton count={1} />
                </div>
            ),
            right: isLastItem && actionRight ? 'true' : undefined,
        });
    }

    const data = generateEmptyArrays(row);

    return (
        <DataTable
            responsive
            striped
            //@ts-ignore
            columns={columns}
            data={data}
        />
    );
};

export default TableSkeleton;
