export const PRIMARY_SALE_ORDER = {
    BASE_PATH: '/primary-sale-order/sale-order/',
    CONVERT_TO_INVOICE_PATH(id: string | number) {
        return this.BASE_PATH + `${id}` + '/edit/convert-to-invoice';
    },
    ADD_PATH() {
        return this.BASE_PATH + 'add';
    },
    ADD_BUTTON_TITLE: 'Add New  Sale Order',
    LIST_PAGE_TITLE: 'Sale Order List',
    EDIT_PAGE_TITLE: 'Edit  Sale Order',
    GET_ALL: 'primary-sale-order/sale-order',
    GET_ONE: 'primary-sale-order/sale-order/edit/',
    CREATE: 'primary-sale-order/sale-order',
    UPDATE: 'primary-sale-order/sale-order/update/',
    APPROVE: 'primary-sale-order/order-approval/update/',
    DELETE: 'primary-sale-order/sale-order/delete/',
    ADD_NEW_TITLE: 'Add New Sale Order',
};
